import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLoaderData, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Form, InputGroup, OverlayTrigger, Spinner, Tooltip, TooltipProps } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import Graph from '@trd/independent-xy-react-d3-graph';
import { line } from 'd3-shape';
import { format } from 'date-fns';

import { FUEL_WEIGHT_PER_GAL } from 'config';
import {
  CreateOutingInput,
  UpdateOutingInput,
  OutingLapInput,
  SessionsByEventQuery,
  useEventsBySeriesQuery,
  useSessionsByEventLazyQuery,
  useCarsBySeriesQuery,
  useDriversQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

import styles from '../index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SERIES_MAP } from 'components/SeriesSelector';

interface Props {
  eventId: string;
  isCustom: boolean;
  setIsCustom: (isCustom: boolean) => void;
}

export default (props: Props) => {
  const { series: seriesPath, outingId } = useParams();
  if (!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const { isCustom, setIsCustom } = props;

  const teams = useLoaderData() as string[];
  const isEdit = Boolean(outingId);
  const darkMode = useSelector(selectDarkMode);

  const [eventId, setEventId] = useState(props.eventId);
  const [date, setDate] = useState('');
  const [yDomain, setYDomain] = useState([0, 50]);
  const [session, setSession] = useState<SessionsByEventQuery['sessions'][number]>();
  const [customLength, setCustomLength] = useState<number>();

  useEffect(() => {
    setEventId(props.eventId || '');
  }, [props.eventId]);

  const {
    control,
    getValues,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<CreateOutingInput | UpdateOutingInput>();

  const { fields: laps, append: appendLap, remove: removeLap } = useFieldArray({ control, name: 'details.laps' });
  const [stateLaps, setStateLaps] = useState([] as OutingLapInput[]);

  const sessionId = useWatch({ control, name: 'session_id' });
  const carId = useWatch({ control, name: 'car_id'});
  const driverId = useWatch({ control, name: 'driver_id'});
  const weightLf = useWatch({ control, name: 'weights.weight_lf' });
  const weightRf = useWatch({ control, name: 'weights.weight_rf' });
  const weightLr = useWatch({ control, name: 'weights.weight_lr' });
  const weightRr = useWatch({ control, name: 'weights.weight_rr' });
  const weightFuel = useWatch({ control, name: 'weights.fuel' });
  const fuelAdded = useWatch({ control, name: 'details.fuel_added' });

  const { data: events, loading: eventsLoading, error: eventsError } = useEventsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { data: cars,
    loading: carsLoading,
    error: carsError,
   } = useCarsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { data: drivers,
    loading: driversLoading,
    error: driversError,
   } = useDriversQuery();

  const [getSessions, {
    data: sessions,
    loading: sessionsLoading,
    error: sessionsError,
  }] = useSessionsByEventLazyQuery();

  useEffect(() => {
    if (!isEdit && !eventId && events?.events && events.events.length > 0) {
      const event = isCustom ? 'custom' : events.events[0].id;
      setEventId(event);
      setValue('series_id', events.events[0].series.id);
    }
  }, [events]);

  useEffect(() => {
    if (eventId && eventId !== 'custom') {
      getSessions({ variables: { eventId }});
    }
  }, [eventId]);

  useEffect(() => {
    const sessionId = getValues('session_id');
    if (sessions?.sessions && sessions.sessions.length > 0 && !sessionId) {
      setValue('session_id', sessions.sessions[0].id);
    }
  }, [sessions]);

  useEffect(() => {
    if (sessionId) {
      const session = sessions?.sessions.find(s => s.id === sessionId);
      if (session) {
        setSession(session);
        if (!eventId) setEventId(session.event.id);
        const date = session.actual_start ?? session.scheduled_start;
        if (date) setDate(format(new Date(date), 'M/d/yyyy'));
      }
    }
  }, [sessionId, sessions]);

  useEffect(() => {
    const updatedLaps = getValues('details.laps');
    setStateLaps(updatedLaps);
  }, [laps]);

  const trackLength = useMemo(() => {
    if(eventId === 'custom') {
      return getValues('custom_length');
    }
    return session?.event.track_configuration.length;
  }, [eventId, customLength, session]);

  const trueLapCount = useMemo(() => {
    return laps[laps.length - 1].lap_time ? laps.length : laps.length - 1;
  }, [laps]);

  useEffect(() => {
    const lapsWithTime = stateLaps.filter(l => l.lap_time);
    const lapTimes: number[] = lapsWithTime.map(l => (l.lap_time as number) / 1000);
    let yDomain = [0, 50];
    if (lapTimes.length > 0) {
      const min = Math.min(...lapTimes);
      const max = Math.max(...lapTimes);
      yDomain = [min - (min * 0.1), max + (max * 0.01)];
    }
    setYDomain(yDomain);
    
    // TODO: Consolidate create/edit outing GraphQL calls and move this
    // calculation up to the save handler
    if(trackLength) {
      setValue('mileage', trueLapCount * trackLength);
    }
  }, [stateLaps, session, trackLength, trueLapCount]);

  useEffect(() => {
    if(stateLaps.length >= 1) {
      if(stateLaps[stateLaps.length - 1].lap_time) {
        appendLap({ number: laps.length + 1, lap_time: null }, { shouldFocus: false });
      }
    }
  }, [stateLaps]);

  const totalWeight = useMemo(() => {
    return (
      ((!weightLf || Number.isNaN(weightLf)) ? 0 : weightLf) +
      ((!weightRf || Number.isNaN(weightRf)) ? 0 : weightRf) +
      ((!weightLr || Number.isNaN(weightLr)) ? 0 : weightLr) +
      ((!weightRr || Number.isNaN(weightRr)) ? 0 : weightRr)
    );
  }, [weightLf, weightRf, weightLr, weightRr]);
  const totalRight = useMemo(() => {
    if (!weightRf && !weightRr) return 0;
    return (
      (weightRf ?? 0) +
      (weightRr ?? 0)
    );
  }, [weightRf, weightRr]);
  const nose = useMemo(() => {
    if (!weightLf || !weightRf || !totalWeight) return '';
    return `${((
      ((weightLf ?? 0) +
      (weightRf ?? 0)) /
      totalWeight
    ) * 100).toFixed(1)}%`;
  }, [weightLf, weightRf, totalWeight]);
  const cross = useMemo(() => {
    if (!weightRf || !weightLr || !totalWeight) return '';
    return `${((
      ((weightRf ?? 0) +
      (weightLr ?? 0)) /
      totalWeight
    ) * 100).toFixed(1)}%`;
  }, [weightRf, weightRr, totalWeight]);
  const fuelWeight = useMemo(() => {
    const weight = (weightFuel ?? 0) * FUEL_WEIGHT_PER_GAL
    return weight.toFixed(2);
  }, [weightFuel]);

  const fuelBurn = useMemo(() => {
    return fuelAdded || 0 / FUEL_WEIGHT_PER_GAL;
  }, [fuelAdded]);
  const fuelMileage = useMemo(() => {
    if (!trackLength) return null;
    if (!fuelBurn || !trueLapCount) return 0;
    const mileage = (trackLength * trueLapCount) / fuelBurn;
    return mileage.toFixed(2);
  }, [session, trueLapCount, fuelBurn]);

  const handleEventChange = async (val: string) => {
    // clear out session_id for new event
    setValue('session_id', '');
    setValue('custom_length', -1);
    setEventId(val);

    // for custom events, we don't use session, but we need a session id for the veritas request
    // TODO don't make requests to veritas for custom events
    if(val === 'custom' && events?.events && events.events.length > 0) {
      setIsCustom(true);
      const sessions = await getSessions({ variables: { eventId }});
      setValue('session_id', sessions.data?.sessions[0].id);
      setValue('custom_length', 0);
    } else {
      setIsCustom(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeLap = (e: any, index: number) => { // react-hook-form marks it as `any`, so shall we
    if (e.target.value && index === laps.length - 1) {
      appendLap({ number: laps.length + 1, lap_time: null, formatted_time: null }, { shouldFocus: false });
    }

    const updatedLaps = getValues('details.laps');
    setStateLaps(updatedLaps);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCustomLengthUpdate = (e: any) => { 
    const length = parseFloat(e.target.value);
    if(length) {
      setCustomLength(length);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRemoveLap = (e: any, index: number) => { 
    if(laps.length <= 1 || !stateLaps[index].lap_time) {
      setValue(`details.laps.${index}.lap_time`, null);
      setValue(`details.laps.${index}.formatted_time`, null);
      return;
    }
    removeLap(index);

    const updatedLaps = getValues('details.laps');
    setStateLaps(updatedLaps.map(l => {
      if(l.number > index) {
        return { ...l, number: l.number--};
      }
      return l;
    }));
  };

  const renderLapTooltip = (props: TooltipProps) => (
    <Tooltip style={{width: 'auto'}} id="lap-tooltip" {...props}>
      <div >
        <span>Enter in seconds</span> 
        <span>(e.g. 90.004)</span>
        <span>or minutes and seconds </span>
        <span>(e.g. 1:30.004)</span>
      </div>
    </Tooltip>
  );


  if (eventsError ?? sessionsError ?? carsError ?? driversError) {
    throw eventsError ?? sessionsError ?? carsError ?? driversError;
  }

  return (
    <>
      <div className={classNames({ [styles.dark]: darkMode }, styles.form)}>
        <div className={styles.colContainer}>
          <div>
            <fieldset className={classNames(styles.fieldset, styles.desc)}>
              <h3>Description</h3>
              <Form.Group className={styles.inputCell}>
                <Form.Control
                  size="sm"
                  placeholder="Outing description"
                  tabIndex={1}
                  {...register('description')}
                />
              </Form.Group>
              <InputGroup>
                {teams.length > 1 ? 
                  <InputGroup.Text>Teams</InputGroup.Text> :
                  <InputGroup.Text>Team</InputGroup.Text>
                }
                <Form.Control
                  as="select"
                  htmlSize={teams.length ?? 1}
                  isInvalid={Boolean(errors.teams)}
                  tabIndex={2}
                  {...register('teams', { required: true })}
                >
                  {teams.map(t => <option key={`team-${t}`}>{t}</option>)}
                </Form.Control>
              </InputGroup>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, eventId === 'custom' ? styles.customEvent : styles.event)}>
              <h3>Event</h3>
              <div className={classNames(styles.labelCell, styles.eventLabel)}>Event</div>
              <Form.Group className={classNames(styles.inputCell, styles.event)}>
                {eventsLoading
                  ? <Spinner animation="border" size="sm" />
                  : (
                    <Form.Select
                      onChange={async e => await handleEventChange(e.target.value)}
                      size="sm"
                      tabIndex={3}
                      value={eventId}
                    >
                      {(events?.events ?? []).map(event => (
                        <option key={event.id} value={event.id}>{event.name}</option>
                      ))}
                      <option key='custom' value='custom'>Custom</option>
                    </Form.Select>
                  )
                }
              </Form.Group>
              {eventId !== 'custom' &&
                <>
                  <div className={classNames(styles.labelCell, styles.sessionLabel)}>Session</div>
                  <Form.Group className={classNames(styles.inputCell, styles.session)}>
                    {sessionsLoading
                      ? <Spinner animation="border" size="sm" />
                      : (
                        <Form.Select size="sm" tabIndex={4} {...register('session_id', { required: true })}>
                          {(sessions?.sessions ?? []).map(session => (
                            <option key={session.id} value={session.id} selected={session.id === sessionId}>{session.name}</option>
                          ))}
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                </>
              }
              <div className={classNames(styles.labelCell, styles.runLabel)}>Run</div>
              <Form.Group className={classNames(styles.inputCell, styles.run)}>
                <Form.Control
                  size="sm"
                  isInvalid={Boolean(errors.run)}
                  tabIndex={5}
                  {...register('run', { required: true, valueAsNumber: true })}
                />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.lengthLabel)}>Length (mi)</div>
              <Form.Group className={classNames(styles.inputCell, styles.length)}>
                {eventId === 'custom' ? 
                  <Form.Control 
                    type="number" 
                    size="sm" 
                    {...register('custom_length', {
                      onChange: e => handleCustomLengthUpdate(e),
                      valueAsNumber: true,
                    })} 
                  /> :
                  <Form.Control size="sm" disabled value={session?.event.track_configuration.length ?? ''} />
                }
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.carLabel)}>Car</div>
              <Form.Group className={classNames(styles.inputCell, styles.car)}>
                {carsLoading
                  ? <Spinner animation="border" size="sm" />
                  : (
                    <Form.Select
                      isInvalid={Boolean(errors.car_id)}
                      size="sm"
                      tabIndex={6}
                      {...register('car_id')}
                    >
                      {(cars?.cars ?? []).map(c => (
                        <option key={c.id} value={c.id} selected={c.id === carId}>{c.number}</option>
                      ))}
                    </Form.Select>
                  )
                }
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.driverLabel)}>Driver</div>
              <Form.Group className={classNames(styles.inputCell, styles.driver)}>
                {driversLoading
                  ? <Spinner animation="border" size="sm" />
                  : (
                    <Form.Select
                      size="sm"
                      tabIndex={6}
                      {...register('driver_id')}
                    >
                      {(drivers?.drivers ?? []).map(driver => (
                        <option key={driver.id} value={driver.id} selected={driver.id === driverId}>{`${driver.first_name} ${driver.last_name}`}</option>
                      ))}
                    </Form.Select>
                  )
                }
              </Form.Group>
              {(eventId === 'custom') ?
                <>
                  <div className={classNames(styles.labelCell, styles.trackLabel)}>Track</div>
                  <Form.Group className={classNames(styles.inputCell, styles.track)}>
                    <Form.Control size="sm" tabIndex={7} {...register('track_name')} />
                  </Form.Group>
                  <div className={classNames(styles.labelCell, styles.dateLabel)}>Date</div>
                  <Form.Group className={classNames(styles.inputCell, styles.date)}>
                    <Form.Control 
                      type="date" 
                      size="sm" 
                      tabIndex={7} 
                      {...register('date')} 
                    />
                  </Form.Group>
                </> :
                <>
                  <div className={classNames(styles.labelCell, styles.trackLabel)}>Track</div>
                  <Form.Group className={classNames(styles.inputCell, styles.track)}>
                    <Form.Select size="sm" disabled>
                      <option>{session?.event.track_configuration.track.long_name}</option>
                    </Form.Select>
                  </Form.Group>
                  <div className={classNames(styles.labelCell, styles.sessionLabel)}>Session</div>
                  <Form.Group className={classNames(styles.inputCell, styles.session)}>
                    {sessionsLoading
                      ? <Spinner animation="border" size="sm" />
                      : (
                        <Form.Select size="sm" tabIndex={4} {...register('session_id', { required: true })}>
                          {(sessions?.sessions ?? []).map(session => (
                            <option key={session.id} value={session.id} selected={session.id === sessionId}>{session.name}</option>
                          ))}
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                  <div className={classNames(styles.labelCell, styles.dateLabel)}>Date</div>
                  <Form.Group className={classNames(styles.inputCell, styles.date)}>
                    <Form.Control size="sm" disabled value={date ?? ''} />
                  </Form.Group>
                </>
              }
              <div className={classNames(styles.labelCell, styles.engineerLabel)}>Engineer</div>
              <Form.Group className={classNames(styles.inputCell, styles.engineer)}>
                <Form.Control size="sm" tabIndex={7} {...register('engineer')} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.timeLabel)}>Time</div>
              <Form.Group className={classNames(styles.inputCell, styles.time)}>
                <Form.Control size="sm" tabIndex={8} {...register('time')} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.weatherLabel)}>Weather</div>
              <Form.Group className={classNames(styles.inputCell, styles.weather)}>
                <Form.Select size="sm" tabIndex={9} {...register('weather')}>
                  <option value=""/>
                  <option value="Sunny">Sunny</option>
                  <option value="Partly Cloudy">Partly Cloudy</option>
                  <option value="Mostly Cloudy">Mostly Cloudy</option>
                  <option value="Cloudy">Cloudy</option>
                  <option value="Wet">Wet</option>
                  <option value="Rain">Rain</option>
                </Form.Select>
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.tempLabel)}>Temp (°F)</div>
              <Form.Group className={classNames(styles.inputCell, styles.temp)}>
                <Form.Control size="sm" tabIndex={10} {...register('temp', { valueAsNumber: true })} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.trackTempLabel)}>Track Temp (°F)</div>
              <Form.Group className={classNames(styles.inputCell, styles.trackTemp)}>
                <Form.Control size="sm" tabIndex={11} {...register('track_temp', { valueAsNumber: true })} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.tires)}>
              <h3>Tires</h3>
              <div className={classNames(styles.labelCell, styles.tireTypeLabel)}>Dry/Wet</div>
              <Form.Group className={classNames(styles.inputCell, styles.tireType)}>
                <Form.Select size="sm" tabIndex={8} {...register('tires.tire_type')}>
                  <option value="DRY">Dry</option>
                  <option value="WET">Wet</option>
                </Form.Select>
              </Form.Group>
              <div className={classNames(styles.calcCell, styles.tireMeasurementsImp)}>245/620R18</div>
              <div className={classNames(styles.labelCell, styles.setLabel)}>Set</div>
              <Form.Group className={classNames(styles.inputCell, styles.set)}>
                <Form.Control size="sm" tabIndex={9} {...register('tires.set', { valueAsNumber: true })} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.pressureLabel)}>Pressure (Cold PSI)</div>
              <Form.Group className={classNames(styles.inputCell, styles.pressureLf)}>
                <Form.Control placeholder="LF" size="sm" tabIndex={10} {...register('tires.pressure_lf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureRf)}>
                <Form.Control placeholder="RF" size="sm" tabIndex={11} {...register('tires.pressure_rf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureLr)}>
                <Form.Control placeholder="LR" size="sm" tabIndex={12} {...register('tires.pressure_lr', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureRr)}>
                <Form.Control placeholder="RR" size="sm" tabIndex={13} {...register('tires.pressure_rr', { valueAsNumber: true })} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.suspension)}>
              <h3>Suspension</h3>
              <div className={classNames(styles.labelCell, styles.frontLabel)}>Front</div>
              <div className={classNames(styles.labelCell, styles.rearLabel)}>Rear</div>
              <div className={classNames(styles.labelCell, styles.leftFrontLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.rightFrontLabel)}>Right</div>
              <div className={classNames(styles.labelCell, styles.leftRearLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.rightRearLabel)}>Right</div>
              <div className={classNames(styles.labelCell, styles.mainSpringLabel)}>Main Spring</div>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringLf)}>
                <Form.Select size="sm" tabIndex={14} {...register('suspension.spring_lf', { valueAsNumber: true })}>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                  <option value={350}>350</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringRf)}>
                <Form.Select size="sm" tabIndex={15} {...register('suspension.spring_rf', { valueAsNumber: true })}>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                  <option value={350}>350</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringLr)}>
                <Form.Select size="sm" tabIndex={16} {...register('suspension.spring_lr', { valueAsNumber: true })}>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                  <option value={350}>350</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringRr)}>
                <Form.Select size="sm" tabIndex={17} {...register('suspension.spring_rr', { valueAsNumber: true })}>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                  <option value={350}>350</option>
                </Form.Select>
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.perchGapLabel)}>Spring Perch Gap (in)</div>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapLf)}>
                <Form.Control size="sm" tabIndex={18} {...register('suspension.perch_gap_lf')} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapRf)}>
                <Form.Control size="sm" tabIndex={19} {...register('suspension.perch_gap_rf')} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapLr)}>
                <Form.Control size="sm" tabIndex={20} {...register('suspension.perch_gap_lr')} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapRr)}>
                <Form.Control size="sm" tabIndex={21} {...register('suspension.perch_gap_rr')} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.compressionClicksLabel)}>Compression Clicks (6)</div>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksLf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.compression_clicks_lf)}
                  size="sm"
                  tabIndex={22}
                  type="number"
                  {...register('suspension.compression_clicks_lf', { min: 0, max: 6, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksRf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.compression_clicks_rf)}
                  size="sm"
                  tabIndex={23}
                  type="number"
                  {...register('suspension.compression_clicks_rf', { min: 0, max: 6, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksLr)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.compression_clicks_lr)}
                  size="sm"
                  tabIndex={24}
                  type="number"
                  {...register('suspension.compression_clicks_lr', { min: 0, max: 6, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksRr)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.compression_clicks_rr)}
                  size="sm"
                  tabIndex={25}
                  type="number"
                  {...register('suspension.compression_clicks_rr', { min: 0, max: 6, valueAsNumber: true })}
                />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.reboundClicksLabel)}>Rebound Clicks (F 9, R 12)</div>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksLf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.rebound_clicks_lf)}
                  size="sm"
                  tabIndex={26}
                  type="number"
                  {...register('suspension.rebound_clicks_lf', { min: 0, max: 45, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksRf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.rebound_clicks_rf)}
                  size="sm"
                  tabIndex={27}
                  type="number"
                  {...register('suspension.rebound_clicks_rf', { min: 0, max: 45, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksLr)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.rebound_clicks_lr)}
                  size="sm"
                  tabIndex={28}
                  type="number"
                  {...register('suspension.rebound_clicks_lr', { min: 0, max: 45, valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksRr)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.rebound_clicks_rr)}
                  size="sm"
                  tabIndex={29}
                  type="number"
                  {...register('suspension.rebound_clicks_rr', { min: 0, max: 45, valueAsNumber: true })}
                />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.camberLetterLabel)}>Camber Letter (A-K)</div>
              <Form.Group className={classNames(styles.inputCell, styles.camberLetterLf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.camber_letter_lf)}
                  size="sm"
                  tabIndex={30}
                  {...register('suspension.camber_letter_lf', { pattern: /^[A-Ka-k]{1}$/i })}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.camberLetterRf)}>
                <Form.Control
                  isInvalid={Boolean(errors.suspension?.camber_letter_lr)}
                  size="sm"
                  tabIndex={31}
                  {...register('suspension.camber_letter_lr', { pattern: /^[A-Ka-k]{1}$/i })}
                />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.swayBarHoleLabel)}>Sway Bar Hole</div>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleLf)}>
                <Form.Select size="sm" tabIndex={32} {...register('suspension.sway_bar_hole_lf', { valueAsNumber: true })}>
                  <option value={1}>1 rwd, firm</option>
                  <option value={2}>2 fwd, soft</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleRf)}>
                <Form.Select size="sm" tabIndex={33} {...register('suspension.sway_bar_hole_rf', { valueAsNumber: true })}>
                  <option value={1}>1 rwd, firm</option>
                  <option value={2}>2 fwd, soft</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleLr)}>
                <Form.Select size="sm" tabIndex={34} {...register('suspension.sway_bar_hole_lr', { valueAsNumber: true })}>
                  <option value={1}>1 rwd, firm</option>
                  <option value={2}>2 fwd, soft</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleRr)}>
                <Form.Select size="sm" tabIndex={35} {...register('suspension.sway_bar_hole_rr', { valueAsNumber: true })}>
                  <option value={1}>1 rwd, firm</option>
                  <option value={2}>2 fwd, soft</option>
                </Form.Select>
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.weights)}>
              <h3>Weights</h3>
              <div className={classNames(styles.labelCell, styles.weightLabel)}>Weight (lbs)</div>
              <Form.Group className={classNames(styles.weightLf, styles.inputCell)}>
                <Form.Control placeholder="LF" size="sm" tabIndex={36} type="number" {...register('weights.weight_lf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightRf, styles.inputCell)}>
                <Form.Control placeholder="RF" size="sm" tabIndex={37} type="number" {...register('weights.weight_rf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightLr, styles.inputCell)}>
                <Form.Control placeholder="LR" size="sm" tabIndex={38} type="number" {...register('weights.weight_lr', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightRr, styles.inputCell)}>
                <Form.Control placeholder="RR" size="sm" tabIndex={39} type="number" {...register('weights.weight_rr', { valueAsNumber: true })} />
              </Form.Group>
              <div className={styles.labelCell}>Total (lbs)</div>
              <div className={styles.calcCell}>{totalWeight}</div>
              <div className={classNames(styles.labelCell, styles.rightLabel)}>Right (lbs)</div>
              <div className={styles.calcCell}>{totalRight}</div>
              <div className={styles.labelCell}>Nose</div>
              <div className={styles.calcCell}>{nose}</div>
              <div className={classNames(styles.labelCell, styles.crossLabel)}>Cross</div>
              <div className={styles.calcCell}>{cross}</div>
              <div className={styles.labelCell}>Driver (lbs)</div>
              <Form.Group>
                <Form.Control size="sm" tabIndex={40} type="number" {...register('weights.driver', { valueAsNumber: true })} />
              </Form.Group>
              <div className={styles.labelCell}>Fuel (gal)</div>
              <Form.Group>
                <Form.Control size="sm" tabIndex={41} type="number" {...register('weights.fuel', { valueAsNumber: true })} />
              </Form.Group>
              <div className={styles.calcCell}>{fuelWeight} lbs</div>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.heights)}>
              <h3>Heights</h3>
              
              <div className={classNames(styles.labelCell, styles.frameHeightsLabel)}>Frame Heights (mm)</div>
              <Form.Group>
                <Form.Control placeholder="LF" size="sm" tabIndex={44} {...register('heights.fender_heights_lf')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RF" size="sm" tabIndex={45} {...register('heights.fender_heights_rf')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="LF" size="sm" tabIndex={48} {...register('heights.frame_heights_lf')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RF" size="sm" tabIndex={49} {...register('heights.frame_heights_rf')} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fenderHeightsLabel)}>Fender Heights (in)</div>
              <Form.Group>
                <Form.Control placeholder="LR" size="sm" tabIndex={42} {...register('heights.fender_heights_lr')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RR" size="sm" tabIndex={43} {...register('heights.fender_heights_rr')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="LR" size="sm" tabIndex={46} {...register('heights.frame_heights_lr')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RR" size="sm" tabIndex={47} {...register('heights.frame_heights_rr')} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.settings)}>
              <h3>Settings</h3>
              <div className={classNames(styles.labelCell, styles.camberLabel)}>Camber (°)</div>
              <Form.Group>
                <Form.Control placeholder="LF" size="sm" tabIndex={50} {...register('settings.camber_lf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RF" size="sm" tabIndex={51} {...register('settings.camber_rf', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="LF" size="sm" tabIndex={54} {...register('settings.toe_lf')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RF" size="sm" tabIndex={55} {...register('settings.toe_rf')} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.toeLabel)}>Toe (mm)</div>
              <Form.Group>
                <Form.Control placeholder="LR" size="sm" tabIndex={52} {...register('settings.camber_lr', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RR" size="sm" tabIndex={53} {...register('settings.camber_rr', { valueAsNumber: true })} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="LR" size="sm" tabIndex={56} {...register('settings.toe_lr')} />
              </Form.Group>
              <Form.Group>
                <Form.Control placeholder="RR" size="sm" tabIndex={57} {...register('settings.toe_rr')} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.aero)}>
              <h3>Aerodynamics</h3>
              <div className={classNames(styles.labelCell, styles.splitterLabel)}>Splitter Height (in)</div>
              <div className={classNames(styles.labelCell, styles.splitterLeftLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.splitterMidLabel)}>Mid</div>
              <div className={classNames(styles.labelCell, styles.splitterRightLabel)}>Right</div>
              <Form.Group className={classNames(styles.inputCell, styles.splitterLeft)}>
                <Form.Control
                  isInvalid={Boolean(errors.aero?.splitter_height_left)}
                  size="sm"
                  tabIndex={60}
                  {...register('aero.splitter_height_left')}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.splitterMid)}>
                <Form.Control
                  isInvalid={Boolean(errors.aero?.splitter_height_mid)}
                  size="sm"
                  tabIndex={61}
                  {...register('aero.splitter_height_mid')}
                />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.splitterRight)}>
                <Form.Control
                  isInvalid={Boolean(errors.aero?.splitter_height_right)}
                  size="sm"
                  tabIndex={62}
                  {...register('aero.splitter_height_right')}
                />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.wingAngleLabel)}>Wing Angle</div>
              <Form.Group className={classNames(styles.inputCell, styles.wingAngle)}>
                <Form.Select size="sm" tabIndex={63} {...register('aero.spoiler_angle', { valueAsNumber: true })}>
                  <option value={1}>1 - Down, min DF</option>
                  <option value={2}>2 - Middle, med DF</option>
                  <option value={3}>3 - Up, max DF</option>
                </Form.Select>
              </Form.Group>
            </fieldset>
          </div>
        </div>

        <fieldset className={classNames(styles.fieldset, styles.details)}>
          <h3>Details</h3>
          <div className={styles.outingLaps}>
            <div className={classNames(styles.labelCell, styles.lapsLabel)}>
              <span>Laps</span> 
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderLapTooltip}
              >
                <FontAwesomeIcon style={{ marginLeft: '5px', marginBottom:'0px'}} icon="circle-info" />
              </OverlayTrigger>
              
            </div>
            <div className={classNames(styles.labelCell, styles.lapCountLabel)}>Total:</div>
            <div className={classNames(styles.calcCell, styles.lapCount)}>{trueLapCount}</div>
            <div className={styles.laps}>
              {laps.map((lap, index) => (
                <React.Fragment key={lap.id}>
                  <div className={styles.labelCell}>{lap.number <= trueLapCount ? lap.number : ""}</div>
                  <Form.Group className={classNames(styles.inputCell)}>
                    <InputGroup>
                      <Form.Control
                        size="sm"
                        tabIndex={64 + index}
                        autoFocus={index === 0 && laps.length === 2}
                        {...register(`details.laps.${index}.formatted_time`, {
                          onChange: e => onChangeLap(e, index),
                        })}
                      />
                      <Button variant="light" size="sm" onClick={e => handleRemoveLap(e, index)}>
                        <FontAwesomeIcon className={classNames(styles.removeButton)} icon="xmark" />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={styles.otherDetails}>
            <div className={classNames(styles.labelCell, styles.changesLabel)}>Outing Changes</div>
            <Form.Group className={classNames(styles.inputCell, styles.changes)}>
              <Form.Control
                as="textarea"
                size="sm"
                tabIndex={63 + laps.length}
                {...register('details.changes')}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.driverCommentsLabel)}>Driver Comments</div>
            <Form.Group className={classNames(styles.inputCell, styles.driverComments)}>
              <Form.Control
                as="textarea"
                size="sm"
                tabIndex={64 + laps.length}
                {...register('details.driver_comments')}
              />
            </Form.Group>
            <div className={styles.graph}>
              <Graph
                margins={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 30,
                }}
                xDomain={[1, Math.max(laps.length, 10)]}
                yDomain={yDomain}
                disableXZoom
                disableYZoom
              >
                {/* eslint-disable @typescript-eslint/no-explicit-any */}
                {({ xScale, yScale, defaultClipPathId }: any) => {
                  const linePath = line()
                    .x((d: any) => xScale(d.number))
                    .y((d: any) => yScale(d.lap_time / 1000));

                  const dots = stateLaps.map((entry: any, i) => (
                    <circle
                      key={`${entry.number} ${i}`}
                      cx={xScale(entry.number)}
                      cy={yScale(entry.lap_time / 1000)}
                      r={3}
                      fill={darkMode ? 'white' : 'black'}
                      clipPath={`url(#${defaultClipPathId})`}
                    />
                  ));

                  return [
                    <path
                      key="line"
                      className={styles.generalLine}
                      fill="transparent"
                      stroke={darkMode ? 'white' : 'black'}
                      d={linePath(stateLaps.filter(l => l.lap_time) as any) as any}
                      clipPath={`url(#${defaultClipPathId})`}
                    />,
                    ...dots,
                  ];
                }}
                {/* eslint-enable @typescript-eslint/no-explicit-any */}
              </Graph>
            </div>
            <div className={classNames(styles.labelCell, styles.maxCoolantTempLabel)}>Max Coolant Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxCoolantTemp)}>
              <Form.Control
                size="sm"
                tabIndex={65 + laps.length}
                {...register('details.max_coolant_temp', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxEngineOilTempLabel)}>Max Engine Oil Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxEngineOilTemp)}>
              <Form.Control
                size="sm"
                tabIndex={66 + laps.length}
                {...register('details.max_engine_oil_temp', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxTransFluidTempLabel)}>Max Trans Fluid Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxTransFluidTemp)}>
              <Form.Control
                size="sm"
                tabIndex={67 + laps.length}
                {...register('details.max_trans_fluid_temp', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxDiffOilTempLabel)}>Max Diff Oil Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxDiffOilTemp)}>
              <Form.Control
                size="sm"
                tabIndex={68 + laps.length}
                {...register('details.max_diff_oil_temp', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.fuelAddedLabel)}>Fuel Added (lbs)</div>
            <Form.Group className={classNames(styles.inputCell, styles.fuelAdded)}>
              <Form.Control
                size="sm"
                tabIndex={69 + laps.length}
                {...register('details.fuel_added', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.fuelBurnLabel)}>Fuel Burn (gal)</div>
            <div className={classNames(styles.calcCell, styles.fuelBurn)}>{fuelBurn}</div>
            <div className={classNames(styles.labelCell, styles.fuelMileageLabel)}>Fuel Mileage (mpg)</div>
            <div className={classNames(styles.calcCell, styles.fuelMileage)}>{fuelMileage}</div>
            <div className={classNames(styles.labelCell, styles.tirePressureLabel)}>Tire Pres (PSI)</div>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureLf)}>
              <Form.Control
                placeholder="LF"
                size="sm"
                tabIndex={82 + laps.length}
                {...register('details.tire_pressure_lf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureRf)}>
              <Form.Control
                placeholder="RF"
                size="sm"
                tabIndex={83 + laps.length}
                {...register('details.tire_pressure_rf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureLr)}>
              <Form.Control
                placeholder="LR"
                size="sm"
                tabIndex={84 + laps.length}
                {...register('details.tire_pressure_lr', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureRr)}>
              <Form.Control
                placeholder="RR"
                size="sm"
                tabIndex={85 + laps.length}
                {...register('details.tire_pressure_rr', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.rotorTempLabel)}>Rotor Temp (°F)</div>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempLf)}>
              <Form.Control
                placeholder="LF"
                size="sm"
                tabIndex={86 + laps.length}
                {...register('details.rotor_temp_lf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempRf)}>
              <Form.Control
                placeholder="RF"
                size="sm"
                tabIndex={87 + laps.length}
                {...register('details.rotor_temp_rf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempLr)}>
              <Form.Control
                placeholder="LR"
                size="sm"
                tabIndex={88 + laps.length}
                {...register('details.rotor_temp_lr', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempRr)}>
              <Form.Control
                placeholder="RR"
                size="sm"
                tabIndex={89 + laps.length}
                {...register('details.rotor_temp_rr', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.tireTempLabel)}>Tire Temp (°F)</div>
            <div className={classNames(styles.labelCell, styles.tireTempLayoutLabel)}>o/m/i/i/m/o</div>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfOuter)}>
              <Form.Control
                placeholder="LF Outer"
                size="sm"
                tabIndex={70 + laps.length}
                {...register('details.tire_temp_lf_outer', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfMiddle)}>
              <Form.Control
                placeholder="LF Middle"
                size="sm"
                tabIndex={71 + laps.length}
                {...register('details.tire_temp_lf_middle', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfInner)}>
              <Form.Control
                placeholder="LF Inner"
                size="sm"
                tabIndex={72 + laps.length}
                {...register('details.tire_temp_lf_inner', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfInner)}>
              <Form.Control
                placeholder="RF Inner"
                size="sm"
                tabIndex={73 + laps.length}
                {...register('details.tire_temp_rf_inner', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfMiddle)}>
              <Form.Control
                placeholder="RF Middle"
                size="sm"
                tabIndex={74 + laps.length}
                {...register('details.tire_temp_rf_middle', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfOuter)}>
              <Form.Control
                placeholder="RF Outer"
                size="sm"
                tabIndex={75 + laps.length}
                {...register('details.tire_temp_rf_outer', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrOuter)}>
              <Form.Control
                placeholder="LR Outer"
                size="sm"
                tabIndex={76 + laps.length}
                {...register('details.tire_temp_lr_outer', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrMiddle)}>
              <Form.Control
                placeholder="LR Middle"
                size="sm"
                tabIndex={77 + laps.length}
                {...register('details.tire_temp_lr_middle', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrInner)}>
              <Form.Control
                placeholder="LR Inner"
                size="sm"
                tabIndex={78 + laps.length}
                {...register('details.tire_temp_lr_inner', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrInner)}>
              <Form.Control
                placeholder="RR Inner"
                size="sm"
                tabIndex={79 + laps.length}
                {...register('details.tire_temp_rr_inner', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrMiddle)}>
              <Form.Control
                placeholder="RR Middle"
                size="sm"
                tabIndex={80 + laps.length}
                {...register('details.tire_temp_rr_middle', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrOuter)}>
              <Form.Control
                placeholder="RR Outer"
                size="sm"
                tabIndex={81 + laps.length}
                {...register('details.tire_temp_rr_outer', { valueAsNumber: true })}
              />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.caliperTempLabel)}>Caliper Temp (°F)</div>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempLf)}>
              <Form.Control
                placeholder="LF"
                size="sm"
                tabIndex={90 + laps.length}
                {...register('details.caliper_temp_lf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempRf)}>
              <Form.Control
                placeholder="RF"
                size="sm"
                tabIndex={91 + laps.length}
                {...register('details.caliper_temp_rf', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempLr)}>
              <Form.Control
                placeholder="LR"
                size="sm"
                tabIndex={92 + laps.length}
                {...register('details.caliper_temp_lr', { valueAsNumber: true })}
              />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempRr)}>
              <Form.Control
                placeholder="RR"
                size="sm"
                tabIndex={93 + laps.length}
                {...register('details.caliper_temp_rr', { valueAsNumber: true })}
              />
            </Form.Group>
          </div>
        </fieldset>
      </div>
    </>
  );
}
