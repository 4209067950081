import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLoaderData, useParams } from 'react-router-dom';
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';

import {
  CreateOutingInput,
  CreatePartInput,
  Part,
  PartConfig,
  UpdateOutingInput,
  useCreatePartMutation,
  useGetPartConfigsBySeriesQuery,
  useGetPartsQuery,
  useResetChassisPartMileageMutation,
} from 'graphql/generated/graphql';
import toaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';

import styles from '../index.module.css';
import { SERIES_MAP } from 'components/SeriesSelector';

export default () => {
  const { series: seriesPath } = useParams();
  if (!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const teams = useLoaderData() as string[];
  const darkMode = useSelector(selectDarkMode);
  const { control } = useFormContext<CreateOutingInput | UpdateOutingInput>();
  const { fields: outingParts, append, remove } = useFieldArray({ control, name: 'parts', keyName: 'form' });
  const { data: partsQuery, error: partsError } = useGetPartsQuery();
  const { data: partConfigsQuery, error: partConfigsError } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const parts = partsQuery?.parts ?? [];
  const partConfigs = partConfigsQuery?.partConfigs ?? [];

  const chassisParts = useMemo(() => {
    const chassisPart = outingParts.find(p => p.config?.type_name === 'chassis');
    return parts.find(p => p.id === chassisPart?.id)?.chassis_parts ?? [];
  }, [parts, outingParts]);

  const [resetChassisPartMileage] = useResetChassisPartMileageMutation({
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error resetting mileage: ${message}`);
    },
  });

  // Create Part form/modal
  const [showCreatePartModal, setShowCreatePartModal] = useState(false);
  const [selectedCreatePartConfig, setSelectedCreatePartConfig] = useState<{ config: PartConfig, position?: string } | null>(null);
  const [createPart, { loading: createPartLoading }] = useCreatePartMutation();
  const {
    handleSubmit,
    register: createPartRegister,
    unregister,
    formState: { errors: createPartErrors },
    reset,
  } = useForm<CreatePartInput>();

  const partChange = (part: Part, partType: string, position?: string) => {
    const existingPartIndex = outingParts.findIndex(p => {
      return p.config?.type_name === partType && (!position || p.position === position);
    });

    if (existingPartIndex > -1 && part) {
      if (outingParts[existingPartIndex].id === part.id) return;

      remove(existingPartIndex);
      append({
        ...part,
        position,
      });
    } else if (part) {
      append({
        ...part,
        position,
      });
    } else { // Existing part, no selected part (user selected empty option)
      remove(existingPartIndex);
    }
  };

  const onAddPartClicked = (partType: string, position?: string) => {
    const config = partConfigs.find(c => c.type_name === partType);
    unregister('exp_date');
    setSelectedCreatePartConfig({ config: config as PartConfig, position });
    setShowCreatePartModal(true);
  };

  const onCreatePartSubmit = async (part: Omit<CreatePartInput, 'config_id'>) => {
    if (!selectedCreatePartConfig) return;

    await createPart({
      variables: {
        partInput: {
          config_id: selectedCreatePartConfig.config.id,
          ...part,
        },
      },
      onCompleted: async ({ part }) => {
        toaster.show({ bg: 'success' }, 'Success', 'Successfully created part');

        const { config: { type_name: partType } } = selectedCreatePartConfig;
        partChange({
          ...part,
          config: {
            ...selectedCreatePartConfig.config,
          },
        } as Part, partType, selectedCreatePartConfig.position);

        setShowCreatePartModal(false);
        reset();
      },
      onError: ({ message }) => {
        toaster.show({ bg: 'danger' }, 'Error', `Error creating part: ${message}`);
      },
    });
  };

  const onPartChange = (partType: string, position?: string) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedPart = parts.find(p => p.id === Number.parseInt(e.target.value, 10));
      partChange(selectedPart as Part, partType, position);
    };
  }

  const getOutingPart = (partType: string, position?: string) => {
    return outingParts.find(p => {
      return p.config?.type_name === partType && (!position || p.position === position);
    });
  };

  const renderPartControls = (partType: string, position?: string) => {
    const config = partConfigs.find(c => c.type_name === partType);

    const outingPart = getOutingPart(partType, position);
    const partsOfType = parts.filter(p => p.config.type_name === partType);
    const filteredParts = partsOfType.filter(p => {
      return !outingParts.some(oP => oP.id === p.id);
    });
    const partSelect = (
      <InputGroup>
        <Form.Select size="sm" onChange={onPartChange(partType, position)} value={outingPart?.id ?? ''}>
          <option />
          {outingPart && <option value={outingPart.id}>{outingPart.serial_number}</option>}
          {filteredParts.map(part => (
            <option key={part.id} value={part.id}>{part.serial_number}</option>
          ))}
        </Form.Select>
        <Button
          onClick={() => onAddPartClicked(partType, position)}
          size="sm"
          variant="secondary"
        >
          <FontAwesomeIcon icon="add" />
        </Button>
      </InputGroup>
    );

    if (!config?.expires) return partSelect;

    return (
      <div className={styles.expiringPart}>
        {partSelect}
        <Form.Control
          disabled
          size="sm"
          type="date"
          value={outingPart?.exp_date ?? ''}
        />
      </div>
    )
  };

  const renderChassisPart = (partType: string) => {
    const chassisPart = chassisParts.find(p => p.config.type_name === partType);
    return (
      <InputGroup>
        <Form.Control
          size="sm"
          disabled
          value={!_.isNil(chassisPart?.mileage) ? `${chassisPart!.mileage.toFixed(2)} mi` : '- mi'}
        />
        <Button
          disabled={!chassisPart}
          onClick={() => {
            if (chassisPart) {
              resetChassisPartMileage({ variables: { chassisId: chassisPart.id } });
            }
          }}
          size="sm"
          variant="secondary"
        >
          Reset
        </Button>
      </InputGroup>
    );
  };

  if (partsError ?? partConfigsError) {
    throw partsError ?? partConfigsError;
  }

  return (
    <>
      <Modal
        show={showCreatePartModal}
        size="sm"
        onHide={() => setShowCreatePartModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create {selectedCreatePartConfig?.config.display_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="mx-auto"
            onSubmit={e => handleSubmit(onCreatePartSubmit)(e)}
          >
            <Form.Group className="mb-3" controlId="formPartNumber">
              <Form.Label>Part Number</Form.Label>
              <Form.Control
                autoFocus
                isInvalid={Boolean(createPartErrors.part_number)}
                required
                size="sm"
                {...createPartRegister('part_number', { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSerialNumber">
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                isInvalid={Boolean(createPartErrors.serial_number)}
                size="sm"
                {...createPartRegister('serial_number', { required: true })}
              />
            </Form.Group>
            {selectedCreatePartConfig?.config.expires && (
              <Form.Group className="mb-3" controlId="formExpirationDate">
                <Form.Label>Expiration Date</Form.Label>
                <Form.Control
                  isInvalid={Boolean(createPartErrors.exp_date)}
                  size="sm"
                  type="date"
                  {...createPartRegister('exp_date', { required: true })}
                />
              </Form.Group>
            )}
            <hr />
            <Form.Group className="mb-3">
              <Form.Label>Teams</Form.Label>
              <Form.Control
                as="select"
                isInvalid={Boolean(createPartErrors.teams)}
                htmlSize={teams.length ?? 1}
                multiple
                {...createPartRegister('teams', { required: 'Team(s) selection is required' })}
              >
                {teams.length === 1 ? 
                    <option selected key={`team-${teams[0]}`}>{teams[0]}</option> : 
                    teams.map(t => <option key={`team-${t}`}>{t}</option>)}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={e => handleSubmit(onCreatePartSubmit)(e)}>
            {createPartLoading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={classNames({ [styles.dark]: darkMode }, styles.buildForm)}>
        <div className={styles.colContainer}>
          <div>
            <fieldset className={classNames(styles.fieldset, styles.body)}>
              <h3>Body</h3>
              <div className={classNames(styles.labelCell, styles.chassisLabel)}>Chassis</div>
              <Form.Group className={classNames(styles.inputCell, styles.chassis)}>
                {renderPartControls('chassis')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fuelCellBladderLabel)}>Fuel Cell Bladder</div>
              <Form.Group className={classNames(styles.inputCell, styles.fuelCellBladder)}>
                {renderPartControls('fuel_cell_bladder')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fireBottleLabel)}>Fire Bottle</div>
              <Form.Group className={classNames(styles.inputCell, styles.fireBottle)}>
                {renderPartControls('fire_bottle')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.seatLabel)}>Seat</div>
              <Form.Group className={classNames(styles.inputCell, styles.seat)}>
                {renderPartControls('seat')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.electronics)}>
              <h3>Electronics</h3>
              <div className={classNames(styles.labelCell, styles.ms6Label)}>MS6</div>
              <Form.Group className={classNames(styles.inputCell, styles.ms6)}>
                {renderPartControls('ms6')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.pbx90Label)}>PBX90</div>
              <Form.Group className={classNames(styles.inputCell, styles.pbx90)}>
                {renderPartControls('pbx90')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.ddu11DashLabel)}>DDU11 Dash</div>
              <Form.Group className={classNames(styles.inputCell, styles.ddu11Dash)}>
                {renderPartControls('ddu11_dash')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.lteTelemetryLabel)}>LTE Telemetry</div>
              <Form.Group className={classNames(styles.inputCell, styles.lteTelemetry)}>
                {renderPartControls('lte65_telemetry')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.absLabel)}>ABS</div>
              <Form.Group className={classNames(styles.inputCell, styles.abs)}>
                {renderPartControls('abs')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.canSwitchLabel)}>CAN Panel</div>
              <Form.Group className={classNames(styles.inputCell, styles.canPanel)}>
                {renderPartControls('can_panel')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.mm510Label)}>MM5.10</div>
              <Form.Group className={classNames(styles.inputCell, styles.mm510)}>
                {renderPartControls('mm510')}
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.powertrain)}>
              <h3>Powertrain</h3>
              <div className={classNames(styles.labelCell, styles.engineLabel)}>Engine</div>
              <Form.Group className={classNames(styles.inputCell, styles.engine)}>
                {renderPartControls('engine')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.engineOilLabel)}>Engine Oil/Filter</div>
              <Form.Group className={classNames(styles.inputCell, styles.engineOil)}>
                {renderChassisPart('engine_oil_filter')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.engineCoolantLabel)}>Engine Coolant</div>
              <Form.Group className={classNames(styles.inputCell, styles.engineCoolant)}>
                {renderChassisPart('engine_coolant')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.sparkPlugsLabel)}>Spark Plugs</div>
              <Form.Group className={classNames(styles.inputCell, styles.sparkPlugs)}>
                {renderChassisPart('spark_plugs')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.airFilterLabel)}>Air Filter</div>
              <Form.Group className={classNames(styles.inputCell, styles.airFilter)}>
                {renderPartControls('air_filter')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.lambdaSensorLabel)}>Lambda Sensor</div>
              <Form.Group className={classNames(styles.inputCell, styles.lambdaSensor)}>
                {renderPartControls('lambda_sensor')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.brakes)}>
              <h3>Brakes</h3>
              <div className={classNames(styles.labelCell, styles.calipersFrontLabel)}>Calipers Front Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.calipersLF)}>
                {renderChassisPart('caliper_left_front')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.calipersRF)}>
                {renderChassisPart('caliper_right_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.calipersRearLabel)}>Calipers Rear Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.calipersLR)}>
                {renderChassisPart('caliper_left_rear')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.calipersRR)}>
                {renderChassisPart('caliper_right_rear')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeFluidLabel)}>Brake Fluid</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeFluid)}>
                {renderChassisPart('brake_fluid')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakePadsFrontLabel)}>Brake Pads Front</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakePadsFront)}>
                {renderChassisPart('brake_pads_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakePadsRearLabel)}>Brake Pads Rear</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakePadsRear)}>
                {renderChassisPart('brake_pads_rear')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeRotorsFrontLabel)}>Brake Rotors Front</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeRotorsFront)}>
                {renderChassisPart('brake_rotors_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeRotorsRearLabel)}>Brake Rotors Rear</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeRotorsRear)}>
                {renderChassisPart('brake_rotors_rear')}
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.driveline)}>
              <h3>Driveline</h3>
              <div className={classNames(styles.labelCell, styles.transmissionLabel)}>Transmission</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmission)}>
                {renderPartControls('transmission')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.transmissionServiceLabel)}>Transmission Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmissionService)}>
                {renderChassisPart('transmission_service')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.transmissionOilLabel)}>Transmission Oil</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmissionOil)}>
                {renderChassisPart('transmission_oil')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.differentialOilFilterCleanLabel)}>Differential Oil/Filter Clean</div>
              <Form.Group className={classNames(styles.inputCell, styles.differentialOilFilterClean)}>
                {renderChassisPart('differential_oil_filter_clean')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.propshaftUJointLabel)}>Propshaft U-Joint Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.propshaftUJoint)}>
                {renderChassisPart('propshaft_ujoint')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.axleLRLabel)}>Axle LR</div>
              <Form.Group className={classNames(styles.inputCell, styles.axleLR)}>
                {renderPartControls('axle_lr')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.axleRRLabel)}>Axle RR</div>
              <Form.Group className={classNames(styles.inputCell, styles.axleRR)}>
                {renderPartControls('axle_rr')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.buildSuspension)}>
              <h3>Suspension</h3>
              <div className={classNames(styles.labelCell, styles.shockFrontLabel)}>Shock/Strut Front Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.shockLF)}>
                {renderChassisPart('shock_left_front')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.shockRF)}>
                {renderChassisPart('shock_right_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.shockRearLabel)}>Shock/Strut Rear Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.shockLR)}>
                {renderChassisPart('shock_left_rear')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.shockRR)}>
                {renderChassisPart('shock_right_rear')}
              </Form.Group>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
}
