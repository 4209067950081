import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { toNumber } from 'lodash'

import styles from "./index.module.css";
import toaster from 'helpers/toaster';

import {
  UpdatePartConfigInput,
  useGetPartConfigsBySeriesQuery,
  useUpdatePartConfigMutation,
} from 'graphql/generated/graphql';
import { SERIES_MAP } from 'components/SeriesSelector';

export default () => {
  const { partConfigId, series: seriesPath } = useParams();
  if(!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const navigate = useNavigate();
  const { state } = useLocation();

  const { refetch } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      id: toNumber(partConfigId),
      display_name: state.display_name,
      mileage_limit: state.mileage_limit,
      mileage_warning_percentage: state.mileage_warning_percentage,
    },
  });

  const [updatePartConfig, {
    loading: updateLoading,
  }] = useUpdatePartConfigMutation({
    ignoreResults: true,
    onCompleted: () => {
      navigate(`/${seriesPath}/part-config`);
      toaster.show({ bg: 'success' }, 'Success', 'Part Config successfully updated');
      refetch();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error updating part config: ${message}`);
    },
  });

  const onSubmit = async (data: UpdatePartConfigInput) => {
    await updatePartConfig({ variables: { partConfigInput: { ...data } }});
  };

  return (
    <>
      <h2 className={classNames(styles.header, "mx-auto")}>Edit Part Config</h2>
      <Form
        className={classNames(styles.form, "mx-auto")}
        onSubmit={e => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Form.Label>Display Name</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            size="sm"
            {...register('display_name', { required: true })}
          />
          <ErrorMessage
            errors={errors}
            name="display_name"
            render={() => <p className={classNames(styles.error_message)}>Display Name is required</p>}
          />
        </Form.Group>
        <Form.Label>Mileage Limit</Form.Label>
        <Form.Group className="mb-3">
          <InputGroup>
            <Form.Control
              type="number"
              size="sm"
              {...register('mileage_limit', {
                setValueAs: v => v ? toNumber(v) : undefined,
              })}
            />
            <InputGroup.Text>Miles</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Label>Mileage Warning Percentage</Form.Label>
        <Form.Group className="mb-3">
          <InputGroup>
            <Form.Control
              type="number"
              size="sm"
              {...register('mileage_warning_percentage', {
                setValueAs: v => v ? toNumber(v) : undefined,
              })}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <div className={styles.actions}>
          <Button variant="secondary" onClick={() => navigate(`/${seriesPath}/part-config`)}>Cancel</Button>
          <Button type="submit">{
            updateLoading ? <Spinner animation="border" size="sm" /> : 'Save'
          }</Button>
        </div>
      </Form>
    </>
  )
};
