import Layout from 'components/Layout';
import SeriesSelector from 'components/SeriesSelector';
import PartConfigList from 'components/PartConfig/List';
import SeriesSummary from 'components/SeriesSummary';
import OutingCreate from 'components/Outing/Create';
import OutingView from 'components/Outing/View';
import PartLifingView from 'components/PartLifingView';
import PartCreate from 'components/PartCreate';
import PartView from 'components/PartView';
import PartConfigCreate from 'components/PartConfig/Create';
import PartConfigEdit from 'components/PartConfig/Edit';
import AuthRoute from 'components/AuthRoute';
import { fetchUserTeams, fetchUserPermissions } from 'helpers/auth';

export default [{
  id: 'root',
  path: '/',
  element: <Layout />,
  loader: fetchUserPermissions,
  children: [{
    index: true,
    element: <SeriesSelector />,
  }, {
    path: ':series',
    children: [{
      index: true,
      element: <SeriesSummary />,
    }, {
      path: 'outing',
      children: [{
        path: 'create',
        element: <OutingCreate />,
        loader: fetchUserTeams,
      }, {
        path: 'edit/:outingId',
        element: <OutingCreate />,
        loader: fetchUserTeams,
      }, {
        path: ':outingId',
        element: <OutingView />,
      }],
    }, {
      path: 'parts',
      children: [{
        index: true,
        element: <PartLifingView />,
      }, {
        path: 'create',
        element: <PartCreate />,
        loader: fetchUserTeams,
      }, {
        path: ':partId',
        element: <PartView />,
      }],
    }, {
      path: 'part-config',
      children: [{
        index: true,
        element: (
          <AuthRoute permissions={['read_part_configs']}>
            <PartConfigList />
          </AuthRoute>
        ),
      }, {
        path: 'create',
        element: (
          <AuthRoute permissions={['read_part_configs', 'write_part_configs']}>
            <PartConfigCreate />
          </AuthRoute>
        ),
      }, {
        path: ':partConfigId',
        element: (
          <AuthRoute permissions={['read_part_configs', 'write_part_configs']}>
            <PartConfigEdit />
          </AuthRoute>
        ),
      }],
    }],
  }],
}];
