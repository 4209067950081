import { useState, useMemo } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { orderBy, get } from 'lodash';

import InteractiveTable, { InteractiveTableData } from 'components/InteractiveTable';
import { useGetPartConfigsBySeriesQuery, PartConfig } from 'graphql/generated/graphql';
import styles from './index.module.css';
import { SERIES_MAP } from 'components/SeriesSelector';

export default () => {
  const { series: seriesPath } = useParams();
  if(!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const navigate = useNavigate();
  const darkMode = useSelector(selectDarkMode);
  const [sortKey, setSortKey] = useState('display_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const { data, error, loading } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const setSortBy = (fieldName: string) => {
    if (sortKey === fieldName) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortKey(fieldName);
      if (sortDirection === 'asc') setSortDirection('desc');
    }
  };

  const columns = [
    {
      title: 'Type Name',
      keyName: 'type_name',
      sortDirection: sortKey === 'type_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('type_name'),
        className: styles.clickable,
      }),
    },
    {
      title: 'Display Name',
      keyName: 'display_name',
      sortDirection: sortKey === 'display_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('display_name'),
        className: styles.clickable,
      }),
    },
    {
      title: 'Serializable',
      keyName: 'serializable',
      sortDirection: sortKey === 'serializable' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('serializable'),
        className: styles.clickable,
      }),
      cellRenderer: (value: PartConfig) => {
        return <FormCheck type="checkbox" checked={value.serializable} disabled />;
      },
    },
    {
      title: 'Expires',
      keyName: 'expires',
      sortDirection: sortKey === 'expires' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('expires'),
        className: styles.clickable,
      }),
      cellRenderer: (value: PartConfig) => {
        return <FormCheck type="checkbox" checked={value.expires} disabled />;
      },
    },
    {
      title: 'Mileage Limit',
      keyName: 'mileage_limit',
      sortDirection: sortKey === 'mileage_limit' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('mileage_limit'),
        className: styles.clickable,
      }),
    },
    {
      title: 'Mileage Warning Threshold',
      keyName: 'mileage_warning_percentage',
      sortDirection: sortKey === 'mileage_warning_percentage' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('mileage_warning_percentage'),
        className: styles.clickable,
      }),
      cellRenderer: (value: PartConfig) => {
        const { mileage_warning_percentage, mileage_limit } = value;
        return <span>{!!mileage_limit && !!mileage_warning_percentage && `${mileage_warning_percentage}%`}</span>;
      },
    }
  ];

  const tableData = useMemo(() => {
    return orderBy(data?.partConfigs, [(p: PartConfig) => {
      const attribute = get(p, sortKey);
      return typeof attribute === 'string' ? attribute.toLowerCase() : attribute || '';
    }], [sortDirection]);
  }, [data?.partConfigs, sortKey, sortDirection]);

  const onAddPartConfigClicked = () => {
    navigate(`/${seriesPath}/part-config/create`);
  };

  if (error) throw error;

  const onRowFunc = (record: InteractiveTableData) => ({
    onClick: () => navigate(`/${seriesPath}/part-config/${record.id}`, { state: record }),
  });

  return (
    <>
      <div className={styles.header}>
        <h2>
          <img
            className='img-fluid'
            style={{ width: '10%', height: '10%' }}
            src={`${process.env.PUBLIC_URL}/img/GR-Badge.png`}
          />
          <span style={{ marginLeft: '5px', marginBottom: '-12px'}}>Part Configs</span>
        </h2>
        <Button variant="primary" onClick={onAddPartConfigClicked}>+ Add Part Config</Button>
      </div>
      <div className={styles.partConfigsTableContainer}>
        <InteractiveTable
          variant={darkMode ? 'dark' : 'light'}
          columns={columns}
          data={tableData}
          onRow={onRowFunc}
          loading={loading}
        />
      </div>
    </>
  );
};