export const fetchUserPermissions = async () => {
  const res = await fetch('/auth/list-user-permissions');
  const { data: permissions } = await res.json();
  return permissions.map((p: { name: string }) => p.name);
};

export const fetchUserTeams = async () => {
  const res = await fetch('/auth/list-user-groups');
  try {
    const { data: groups } = await res.json() as { data: { name: string }[] };
    return groups.reduce((acc, group) => {
      const match = group.name.match(/^GR Setup Team (?<teamName>.*)$/);
      if (match) acc.push(match.groups!.teamName);
      return acc;
    }, [] as string[]);
  } catch (e) {
    console.error('Failed to fetch user groups/teams:', e);
    return [];
  }
};
