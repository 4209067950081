import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import classNames from 'classnames';
import Graph from '@trd/independent-xy-react-d3-graph';
import { line } from 'd3-shape';

import { FUEL_WEIGHT_PER_GAL } from 'config';
import { selectDarkMode } from 'reducers/ui';

import styles from "../index.module.css"
import { Outing, useCarsBySeriesQuery, useDriversQuery } from 'graphql/generated/graphql';
import { millisecondsToTimeFormat } from 'helpers/timeFormat';
import { SERIES_MAP } from 'components/SeriesSelector';

interface Props {
  outing?: Outing,
}

export default (props: Props) => {
  const { series: seriesPath, outingId } = useParams();
  if (!seriesPath || !outingId) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const darkMode = useSelector(selectDarkMode);

  const {
    description, teams, session, car_id, driver_id, weights, heights, settings, tires,
    suspension, aero, details, custom_length, track_name, date: custom_date
  } = props?.outing ?? {};

  let date = session?.actual_start ?? session?.scheduled_start;
  if (date) date = format(new Date(date), 'M/d/yyyy');

  const { data: cars,
    loading: carsLoading,
   } = useCarsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { data: drivers,
    loading: driversLoading,
   } = useDriversQuery();

  const totalWeight = useMemo(() => {
    return (
      (weights?.weight_lf ?? 0) +
      (weights?.weight_rf ?? 0) +
      (weights?.weight_lr ?? 0) +
      (weights?.weight_rr ?? 0)
    );
  }, [weights?.weight_lf, weights?.weight_rf, weights?.weight_lr, weights?.weight_rr]);
  const totalRight = useMemo(() => {
    return (
      (weights?.weight_rf ?? 0) +
      (weights?.weight_rr ?? 0)
    );
  }, [weights?.weight_rf, weights?.weight_rr]);
  const nose = useMemo(() => {
    return totalWeight <= 0 ? 0 : ((
      ((weights?.weight_lf ?? 0) +
      (weights?.weight_rf ?? 0)) /
      totalWeight
    ) * 100).toFixed(1);
  }, [weights?.weight_lf, weights?.weight_rf, totalWeight]);
  const cross = useMemo(() => {
    return totalWeight <= 0 ? 0 : ((
      ((weights?.weight_rf ?? 0) +
      (weights?.weight_lr ?? 0)) /
      totalWeight
    ) * 100).toFixed(1);
  }, [weights?.weight_rf, weights?.weight_lr, totalWeight]);
  const fuelWeight = useMemo(() => {
    const weight = (weights?.fuel ?? 0) * FUEL_WEIGHT_PER_GAL;
    return weight.toFixed(2);
  }, [weights?.fuel]);

  const fuelBurn = useMemo(() => {
    return details?.fuel_added || 0 / FUEL_WEIGHT_PER_GAL;
  }, [details?.fuel_added]);
  const fuelMileage = useMemo(() => {
    if (!session?.event.track_configuration.length) return null;
    if (!fuelBurn || !details?.laps.length) return 0;
    const mileage = (session.event.track_configuration.length * details.laps.length) / fuelBurn;
    return mileage.toFixed(2);
  }, [session, details?.laps, fuelBurn]);

  let yDomain = [0, 50];
  if (details?.laps) {
    const lapTimes = details.laps.filter(l => l.lap_time).map(l => (l.lap_time as number) / 1000);
    const min = Math.min(...lapTimes);
    const max = Math.max(...lapTimes);
    yDomain = [min - (min * 0.1), max + (max * 0.01)];
  }

  const displaySwayBarHole = (swayBarValue?: number | null) => {
    return swayBarValue === 1 ? '1 rwd, firm' : swayBarValue === 2 ? '2 fwd, soft' : '';
  };

  return (
    <>
      <Form className={classNames({ [styles.dark]: darkMode }, styles.form)}>
        <div className={styles.colContainer}>
          <div>
            <fieldset className={classNames(styles.fieldset, styles.desc)} disabled>
              <h3>Description</h3>
              <Form.Group className={styles.inputCell}>
                <Form.Control readOnly size="sm" value={description ?? ''} />
              </Form.Group>
              <InputGroup>
                {teams?.length && teams.length > 1 ? 
                  <InputGroup.Text>Teams</InputGroup.Text> :
                  <InputGroup.Text>Team</InputGroup.Text>
                }
                <Form.Control
                  as="select"
                  disabled
                  htmlSize={teams?.length || 1}
                >
                  {teams?.map(t => <option key={`team-${t}`}>{t}</option>)}
                </Form.Control>
              </InputGroup>
            </fieldset>
            
            {custom_length || custom_length === 0 ? 
              <fieldset className={classNames(styles.fieldset, styles.customEvent)} disabled>
                <h3>Event</h3>
                <div className={classNames(styles.labelCell, styles.eventLabel)}>Event</div>
                <Form.Group className={classNames(styles.inputCell, styles.event)}>
                  <Form.Select disabled size="sm">
                    <option>Custom</option>
                  </Form.Select>
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.trackLabel)}>Track</div>
                <Form.Group className={classNames(styles.inputCell, styles.track)}>
                  <Form.Select disabled size="sm">
                    <option>{track_name}</option>
                  </Form.Select>
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.runLabel)}>Run</div>
                <Form.Group className={classNames(styles.inputCell, styles.run)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.run ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.lengthLabel)}>Length (mi)</div>
                <Form.Group className={classNames(styles.inputCell, styles.length)}>
                  <Form.Control readOnly size="sm" value={custom_length} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.carLabel)}>Car</div>
                <Form.Group className={classNames(styles.inputCell, styles.car)}>
                  {carsLoading
                    ? <Spinner animation="border" size="sm" />
                    : (
                      <Form.Select
                        disabled
                        size="sm"
                        tabIndex={6}
                      >
                        {(cars?.cars ?? []).map(c => (
                          <option key={c.id} value={c.id} selected={c.id === car_id}>{c.number}</option>
                        ))}
                      </Form.Select>
                    )
                  }
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.driverLabel)}>Driver</div>
                <Form.Group className={classNames(styles.inputCell, styles.driver)}>
                  {driversLoading
                    ? <Spinner animation="border" size="sm" />
                    : (
                      <Form.Select
                        disabled
                        size="sm"
                        tabIndex={6}
                      >
                        {(drivers?.drivers ?? []).map(driver => (
                          <option key={driver.id} value={driver.id} selected={driver.id === driver_id}>{`${driver.first_name} ${driver.last_name}`}</option>
                        ))}
                      </Form.Select>
                    )
                  }
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.dateLabel)}>Date</div>
                <Form.Group className={classNames(styles.inputCell, styles.date)}>
                  <Form.Control readOnly type="date" size="sm" value={custom_date ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.engineerLabel)}>Engineer</div>
                <Form.Group className={classNames(styles.inputCell, styles.engineer)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.engineer ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.timeLabel)}>Time</div>
                <Form.Group className={classNames(styles.inputCell, styles.time)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.time ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.weatherLabel)}>Weather</div>
                <Form.Group className={classNames(styles.inputCell, styles.weather)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.weather ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.tempLabel)}>Temp (°F)</div>
                <Form.Group className={classNames(styles.inputCell, styles.temp)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.temp ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.trackTempLabel)}>Track Temp (°F)</div>
                <Form.Group className={classNames(styles.inputCell, styles.trackTemp)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.track_temp ?? ''} />
                </Form.Group>
              </fieldset> :
              <fieldset className={classNames(styles.fieldset, styles.event)} disabled>
                <h3>Event</h3>
                <div className={classNames(styles.labelCell, styles.eventLabel)}>Event</div>
                <Form.Group className={classNames(styles.inputCell, styles.event)}>
                  <Form.Select disabled size="sm">
                    <option>{session?.event.name ?? ''}</option>
                  </Form.Select>
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.sessionLabel)}>Session</div>
                <Form.Group className={classNames(styles.inputCell, styles.session)}>
                  <Form.Select disabled size="sm">
                    <option>{session?.name ?? ''}</option>
                  </Form.Select>
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.trackLabel)}>Track</div>
                <Form.Group className={classNames(styles.inputCell, styles.track)}>
                  <Form.Select disabled size="sm">
                    <option>{session?.event.track_configuration.track.long_name ?? ''}</option>
                  </Form.Select>
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.runLabel)}>Run</div>
                <Form.Group className={classNames(styles.inputCell, styles.run)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.run ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.lengthLabel)}>Length (mi)</div>
                <Form.Group className={classNames(styles.inputCell, styles.length)}>
                  <Form.Control readOnly size="sm" value={session?.event.track_configuration.length ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.dateLabel)}>Date</div>
                <Form.Group className={classNames(styles.inputCell, styles.date)}>
                  <Form.Control readOnly size="sm" value={date ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.carLabel)}>Car</div>
                <Form.Group className={classNames(styles.inputCell, styles.car)}>
                  {carsLoading
                    ? <Spinner animation="border" size="sm" />
                    : (
                      <Form.Select
                        disabled
                        size="sm"
                        tabIndex={6}
                      >
                        {(cars?.cars ?? []).map(c => (
                          <option key={c.id} value={c.id} selected={c.id === car_id}>{c.number}</option>
                        ))}
                      </Form.Select>
                    )
                  }
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.driverLabel)}>Driver</div>
                <Form.Group className={classNames(styles.inputCell, styles.driver)}>
                  {driversLoading
                    ? <Spinner animation="border" size="sm" />
                    : (
                      <Form.Select
                        disabled
                        size="sm"
                        tabIndex={6}
                      >
                        {(drivers?.drivers ?? []).map(driver => (
                          <option key={driver.id} value={driver.id} selected={driver.id === driver_id}>{`${driver.first_name} ${driver.last_name}`}</option>
                        ))}
                      </Form.Select>
                    )
                  }
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.engineerLabel)}>Engineer</div>
                <Form.Group className={classNames(styles.inputCell, styles.engineer)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.engineer ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.timeLabel)}>Time</div>
                <Form.Group className={classNames(styles.inputCell, styles.time)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.time ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.weatherLabel)}>Weather</div>
                <Form.Group className={classNames(styles.inputCell, styles.weather)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.weather ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.tempLabel)}>Temp (°F)</div>
                <Form.Group className={classNames(styles.inputCell, styles.temp)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.temp ?? ''} />
                </Form.Group>
                <div className={classNames(styles.labelCell, styles.trackTempLabel)}>Track Temp (°F)</div>
                <Form.Group className={classNames(styles.inputCell, styles.trackTemp)}>
                  <Form.Control readOnly size="sm" value={props?.outing?.track_temp ?? ''} />
                </Form.Group>
              </fieldset>
            }
            <fieldset className={classNames(styles.fieldset, styles.tires)} disabled>
              <h3>Tires</h3>
              <div className={classNames(styles.labelCell, styles.tireTypeLabel)}>Dry/Wet</div>
              <Form.Group className={classNames(styles.inputCell, styles.tireType)}>
                <Form.Select disabled size="sm">
                  <option>{tires?.tire_type}</option>
                </Form.Select>
              </Form.Group>
              <div className={classNames(styles.calcCell, styles.tireMeasurementsImp)}>245/620R18</div>
              <div className={classNames(styles.labelCell, styles.setLabel)}>Set</div>
              <Form.Group className={classNames(styles.inputCell, styles.set)}>
                <Form.Control readOnly size="sm" value={tires?.set ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.pressureLabel)}>Pressure (Cold PSI)</div>
              <Form.Group className={classNames(styles.inputCell, styles.pressureLf)}>
                <Form.Control readOnly size="sm" value={tires?.pressure_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureRf)}>
                <Form.Control readOnly size="sm" value={tires?.pressure_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureLr)}>
                <Form.Control readOnly size="sm" value={tires?.pressure_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.pressureRr)}>
                <Form.Control readOnly size="sm" value={tires?.pressure_rr ?? ''} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.suspension)} disabled>
              <h3>Suspension</h3>
              <div className={classNames(styles.labelCell, styles.frontLabel)}>Front</div>
              <div className={classNames(styles.labelCell, styles.rearLabel)}>Rear</div>
              <div className={classNames(styles.labelCell, styles.leftFrontLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.rightFrontLabel)}>Right</div>
              <div className={classNames(styles.labelCell, styles.leftRearLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.rightRearLabel)}>Right</div>
              <div className={classNames(styles.labelCell, styles.mainSpringLabel)}>Main Spring</div>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringLf)}>
                <Form.Control readOnly size="sm" value={suspension?.spring_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringRf)}>
                <Form.Control readOnly size="sm" value={suspension?.spring_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringLr)}>
                <Form.Control readOnly size="sm" value={suspension?.spring_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.mainSpringRr)}>
                <Form.Control readOnly size="sm" value={suspension?.spring_rr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.perchGapLabel)}>Spring Perch Gap (in)</div>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapLf)}>
                <Form.Control readOnly size="sm" value={suspension?.perch_gap_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapRf)}>
                <Form.Control readOnly size="sm" value={suspension?.perch_gap_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapLr)}>
                <Form.Control readOnly size="sm" value={suspension?.perch_gap_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.perchGapRr)}>
                <Form.Control readOnly size="sm" value={suspension?.perch_gap_rr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.compressionClicksLabel)}>Compression Clicks (6)</div>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksLf)}>
                <Form.Control readOnly size="sm" value={suspension?.compression_clicks_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksRf)}>
                <Form.Control readOnly size="sm" value={suspension?.compression_clicks_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksLr)}>
                <Form.Control readOnly size="sm" value={suspension?.compression_clicks_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.compressionClicksRr)}>
                <Form.Control readOnly size="sm" value={suspension?.compression_clicks_rr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.reboundClicksLabel)}>Rebound Clicks (F 9, R 12)</div>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksLf)}>
                <Form.Control readOnly size="sm" value={suspension?.rebound_clicks_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksRf)}>
                <Form.Control readOnly size="sm" value={suspension?.rebound_clicks_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksLr)}>
                <Form.Control readOnly size="sm" value={suspension?.rebound_clicks_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.reboundClicksRr)}>
                <Form.Control readOnly size="sm" value={suspension?.rebound_clicks_rr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.camberLetterLabel)}>Camber Letter (A-K)</div>
              <Form.Group className={classNames(styles.inputCell, styles.camberLetterLf)}>
                <Form.Control readOnly size="sm" value={suspension?.camber_letter_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.camberLetterRf)}>
                <Form.Control readOnly size="sm" value={suspension?.camber_letter_lr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.swayBarHoleLabel)}>Sway Bar Hole</div>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleLf)}>
                <Form.Control readOnly size="sm" value={displaySwayBarHole(suspension?.sway_bar_hole_lf)} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleRf)}>
                <Form.Control readOnly size="sm" value={displaySwayBarHole(suspension?.sway_bar_hole_rf)} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleLr)}>
                <Form.Control readOnly size="sm" value={displaySwayBarHole(suspension?.sway_bar_hole_lr)} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.swayBarHoleRr)}>
                <Form.Control readOnly size="sm" value={displaySwayBarHole(suspension?.sway_bar_hole_rr)} />
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.weights)} disabled>
              <h3>Weights</h3>
              <div className={classNames(styles.labelCell, styles.weightLabel)}>Weight (lbs)</div>
              <Form.Group className={classNames(styles.weightLf, styles.inputCell)}>
                <Form.Control readOnly size="sm" value={weights?.weight_lf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightRf, styles.inputCell)}>
                <Form.Control readOnly size="sm" value={weights?.weight_rf ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightLr, styles.inputCell)}>
                <Form.Control readOnly size="sm" value={weights?.weight_lr ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.weightRr, styles.inputCell)}>
                <Form.Control readOnly size="sm" value={weights?.weight_rr ?? ''} />
              </Form.Group>
              <div className={styles.labelCell}>Total (lbs)</div>
              <div className={styles.calcCell}>{totalWeight}</div>
              <div className={classNames(styles.labelCell, styles.rightLabel)}>Right (lbs)</div>
              <div className={styles.calcCell}>{totalRight}</div>
              <div className={styles.labelCell}>Nose</div>
              <div className={styles.calcCell}>{nose}%</div>
              <div className={classNames(styles.labelCell, styles.crossLabel)}>Cross</div>
              <div className={styles.calcCell}>{cross}%</div>
              <div className={styles.labelCell}>Driver (lbs)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={weights?.driver ?? ''} />
              </Form.Group>
              <div className={styles.labelCell}>Fuel (gal)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={weights?.fuel ?? ''} />
              </Form.Group>
              <div className={styles.calcCell}>{fuelWeight} lbs</div>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.heights)} disabled>
              <h3>Heights</h3>
              
              <div className={classNames(styles.labelCell, styles.frameHeightsLabel)}>Frame Heights (mm)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.fender_heights_lr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.fender_heights_rr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.frame_heights_lr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.frame_heights_rr ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fenderHeightsLabel)}>Fender Heights (in)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.fender_heights_lf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.fender_heights_rf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.frame_heights_lf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={heights?.frame_heights_rf ?? ''} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.settings)} disabled>
              <h3>Settings</h3>
              <div className={classNames(styles.labelCell, styles.camberLabel)}>Camber (°)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.camber_lf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.camber_rf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.toe_lf ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.toe_rf ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.toeLabel)}>Toe (mm)</div>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.camber_lr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.camber_rr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.toe_lr ?? ''} />
              </Form.Group>
              <Form.Group>
                <Form.Control readOnly size="sm" value={settings?.toe_rr ?? ''} />
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.aero)} disabled>
              <h3>Aerodynamics</h3>
              <div className={classNames(styles.labelCell, styles.splitterLabel)}>Splitter Height (in)</div>
              <div className={classNames(styles.labelCell, styles.splitterLeftLabel)}>Left</div>
              <div className={classNames(styles.labelCell, styles.splitterMidLabel)}>Mid</div>
              <div className={classNames(styles.labelCell, styles.splitterRightLabel)}>Right</div>
              <Form.Group className={classNames(styles.inputCell, styles.splitterLeft)}>
                <Form.Control readOnly size="sm" value={aero?.splitter_height_left ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.splitterMid)}>
                <Form.Control readOnly size="sm" value={aero?.splitter_height_mid ?? ''} />
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.splitterRight)}>
                <Form.Control readOnly size="sm" value={aero?.splitter_height_right ?? ''} />
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.wingAngleLabel)}>Wing Angle</div>
              <Form.Group className={classNames(styles.inputCell, styles.wingAngle)}>
                <Form.Select disabled size="sm" value={aero?.spoiler_angle ?? ''}>
                  <option value={1}>1 - Down, min DF</option>
                  <option value={2}>2 - Middle, med DF</option>
                  <option value={3}>3 - Up, max DF</option>
                </Form.Select>
              </Form.Group>
            </fieldset>
          </div>
        </div>

        <fieldset className={classNames(styles.fieldset, styles.details)} disabled>
          <h3>Details</h3>
          <div className={styles.outingLaps}>
            <div className={classNames(styles.labelCell, styles.lapsLabel)}>Laps</div>
            <div className={classNames(styles.labelCell, styles.lapCountLabel)}>Total:</div>
            <div className={classNames(styles.calcCell, styles.lapCount)}>{details?.laps.length}</div>
            <div className={styles.laps}>
              {details?.laps.map((lap, index) => (
                <React.Fragment key={`laps-${index}`}>
                  <div className={styles.labelCell}>{lap.number}</div>
                  <Form.Group className={classNames(styles.inputCell)}>
                    <Form.Control readOnly size="sm" value={millisecondsToTimeFormat(details?.laps[index].lap_time ?? 0)} />
                  </Form.Group>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={styles.otherDetails}>
            <div className={classNames(styles.labelCell, styles.changesLabel)}>Outing Changes</div>
            <Form.Group className={classNames(styles.inputCell, styles.changes)}>
              <Form.Control
                as="textarea" readOnly size="sm" value={details?.changes ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.driverCommentsLabel)}>Driver Comments</div>
            <Form.Group className={classNames(styles.inputCell, styles.driverComments)}>
              <Form.Control
                as="textarea" readOnly size="sm" value={details?.driver_comments ?? ''} />
            </Form.Group>
            <div className={styles.graph}>
              <Graph
                margins={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 30,
                }}
                xDomain={[1, Math.max(details?.laps.length ?? 1, 10)]}
                yDomain={yDomain}
                disableXZoom
                disableYZoom
              >
                {/* eslint-disable @typescript-eslint/no-explicit-any */}
                {({ xScale, yScale, defaultClipPathId }: any) => {
                  const linePath = line()
                    .x((d: any) => xScale(d.number))
                    .y((d: any) => yScale(d.lap_time / 1000));

                  const dots = details?.laps.map((entry: any, i) => (
                    <circle
                      key={`${entry.number} ${i}`}
                      cx={xScale(entry.number)}
                      cy={yScale(entry.lap_time / 1000)}
                      r={3}
                      fill={darkMode ? 'white' : 'black'}
                      clipPath={`url(#${defaultClipPathId})`}
                    />
                  )) ?? [];

                  return [
                    <path
                      key="line"
                      className={styles.generalLine}
                      fill="transparent"
                      stroke={darkMode ? 'white' : 'black'}
                      d={linePath(details?.laps ?? [] as any) as any}
                      clipPath={`url(#${defaultClipPathId})`}
                    />,
                    ...dots,
                  ];
                }}
                {/* eslint-enable @typescript-eslint/no-explicit-any */}
              </Graph>
            </div>
            <div className={classNames(styles.labelCell, styles.maxCoolantTempLabel)}>Max Coolant Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxCoolantTemp)}>
              <Form.Control readOnly size="sm" value={details?.max_coolant_temp ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxEngineOilTempLabel)}>Max Engine Oil Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxEngineOilTemp)}>
              <Form.Control readOnly size="sm" value={details?.max_engine_oil_temp ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxTransFluidTempLabel)}>Max Trans Fluid Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxTransFluidTemp)}>
              <Form.Control readOnly size="sm" value={details?.max_trans_fluid_temp ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.maxDiffOilTempLabel)}>Max Diff Oil Temp (°C)</div>
            <Form.Group className={classNames(styles.inputCell, styles.maxDiffOilTemp)}>
              <Form.Control readOnly size="sm" value={details?.max_diff_oil_temp ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.fuelAddedLabel)}>Fuel Added (lbs)</div>
            <Form.Group className={classNames(styles.inputCell, styles.fuelAdded)}>
              <Form.Control readOnly size="sm" value={details?.fuel_added ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.fuelBurnLabel)}>Fuel Burn (gal)</div>
            <div className={classNames(styles.calcCell, styles.fuelBurn)}>{fuelBurn}</div>
            <div className={classNames(styles.labelCell, styles.fuelMileageLabel)}>Fuel Mileage (mpg)</div>
            <div className={classNames(styles.calcCell, styles.fuelMileage)}>{fuelMileage}</div>
            <div className={classNames(styles.labelCell, styles.tirePressureLabel)}>Tire Pres (PSI)</div>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureLf)}>
              <Form.Control readOnly size="sm" value={details?.tire_pressure_lf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureRf)}>
              <Form.Control readOnly size="sm" value={details?.tire_pressure_rf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureLr)}>
              <Form.Control readOnly size="sm" value={details?.tire_pressure_lr ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tirePressureRr)}>
              <Form.Control readOnly size="sm" value={details?.tire_pressure_rr ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.rotorTempLabel)}>Rotor Temp (°F)</div>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempLf)}>
              <Form.Control readOnly size="sm" value={details?.rotor_temp_lf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempRf)}>
              <Form.Control readOnly size="sm" value={details?.rotor_temp_rf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempLr)}>
              <Form.Control readOnly size="sm" value={details?.rotor_temp_lr ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.rotorTempRr)}>
              <Form.Control readOnly size="sm" value={details?.rotor_temp_rr ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.tireTempLabel)}>Tire Temp (°F)</div>
            <div className={classNames(styles.labelCell, styles.tireTempLayoutLabel)}>o/m/i/i/m/o</div>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfOuter)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lf_outer ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfMiddle)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lf_middle ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLfInner)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lf_inner ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfInner)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rf_inner ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfMiddle)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rf_middle ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRfOuter)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rf_outer ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrOuter)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lr_outer ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrMiddle)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lr_middle ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempLrInner)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_lr_inner ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrInner)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rr_inner ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrMiddle)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rr_middle ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.tireTempRrOuter)}>
              <Form.Control readOnly size="sm" value={details?.tire_temp_rr_outer ?? ''} />
            </Form.Group>
            <div className={classNames(styles.labelCell, styles.caliperTempLabel)}>Caliper Temp (°F)</div>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempLf)}>
              <Form.Control readOnly size="sm" value={details?.caliper_temp_lf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempRf)}>
              <Form.Control readOnly size="sm" value={details?.caliper_temp_rf ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempLr)}>
              <Form.Control readOnly size="sm" value={details?.caliper_temp_lr ?? ''} />
            </Form.Group>
            <Form.Group className={classNames(styles.inputCell, styles.caliperTempRr)}>
              <Form.Control readOnly size="sm" value={details?.caliper_temp_rr ?? ''} />
            </Form.Group>
          </div>
        </fieldset>
      </Form>
    </>
  );
};
