import { Link, useParams, useRouteLoaderData } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import styles from './index.module.css';
import classNames from 'classnames';

export default () => {
  const { series: seriesPath } = useParams();
  const permissions = useRouteLoaderData('root') as string[];

  return (
    <Nav className="flex-column">
      <Nav.Item className={styles.navListItem}>
        <Link className="nav-link" to="/">Series Select</Link>
      </Nav.Item>
      {seriesPath && (
        <>
          <hr />
          {permissions.includes('read_part_configs') && (
            <Nav.Item className={styles.navListItem}>
              <Link className="nav-link" to={`/${seriesPath}/part-config`}>Part Configs</Link>
              <Nav.Item className={classNames(styles.navListItem, styles.navSubListItem)}>
                <Link className="nav-link" to={`/${seriesPath}/part-config/create`}>Add Part Config</Link>
              </Nav.Item>
            </Nav.Item>
          )}
          <Nav.Item className={styles.navListItem}>
            <Link className="nav-link" to={`/${seriesPath}`}>Summary</Link>
            <Nav.Item className={classNames(styles.navListItem, styles.navSubListItem)}>
              <Link className="nav-link" to={`/${seriesPath}/outing/create`}>Add Outing</Link>
            </Nav.Item>
          </Nav.Item>
          <Nav.Item className={styles.navListItem}>
            <Link className="nav-link" to={`/${seriesPath}/parts`}>Part Lifing</Link>
            <Nav.Item className={classNames(styles.navListItem, styles.navSubListItem)}>
              <Link className="nav-link" to={`/${seriesPath}/parts/create`}>Add Part</Link>
            </Nav.Item>
          </Nav.Item>
        </>
      )}
    </Nav>
  );
};
