import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, FormCheck, Modal, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { orderBy, get, reduce } from 'lodash';

import InteractiveTable, { InteractiveTableData } from 'components/InteractiveTable';
import toaster from 'helpers/toaster';
import {
  useGetPartsQuery,
  useGetPartConfigsBySeriesQuery,
  useArchivePartMutation,
  PartConfig,
  Part,
  PatchPartInput,
  usePatchPartMutation,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import { SERIES_MAP } from 'components/SeriesSelector';

interface MileageMap {
  [key: string]: number | null | undefined;
}

export default () => {
  const { series: seriesPath } = useParams();
  const navigate = useNavigate();
  if (!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const [sortKey, setSortKey] = useState('config.display_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [mileageLimits, setMileageLimits] = useState({});
  const [mileageWarnings, setMileageWarnings] = useState({});
  const [selectedPartId, setSelectedPartId] = useState<number>();
  const [showEditPartModal, setShowEditPartModal] = useState(false);

  const darkMode = useSelector(selectDarkMode);

  const { data, error, refetch, loading } = useGetPartsQuery();
  const { data: partConfigsData, error: partConfigsError } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  useEffect(() => {
    setMileageLimits(reduce(partConfigsData?.partConfigs, (acc: MileageMap, val: PartConfig) => {
      acc[val.type_name] = val.mileage_limit;
      return acc;
    }, {}));
    setMileageWarnings(reduce(partConfigsData?.partConfigs, (acc: MileageMap, val: PartConfig) => {
      acc[val.type_name] = val.mileage_warning_percentage;
      return acc;
    }, {}))
  }, [partConfigsData?.partConfigs])

  const setSortBy = (fieldName: string) => {
    if (sortKey === fieldName) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortKey(fieldName);
      if (sortDirection === 'asc') setSortDirection('desc');
    }
  };

  const [archivePart] = useArchivePartMutation({
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({ bg: 'success' }, 'Success', 'Part successfully archived');
      refetch();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'error' }, 'Error', `Error creating part: ${message}`);
    },
  })

  const [patchPart, { loading: patchLoading }] = usePatchPartMutation({
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({ bg: 'success' }, 'Success', 'Part successfully updated');
      refetch();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'error' }, 'Error', `Error updating part: ${message}`);
    },
  })

  const { handleSubmit, register, formState: { errors }, reset } = useForm<PatchPartInput>();

  useEffect(() => {
    if (selectedPart) {
      const { id, part_number, serial_number, exp_date } = selectedPart;
      reset({
        id,
        part_number,
        serial_number,
        exp_date,
      });
    }
  }, [selectedPartId])

  const onArchiveClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, partId: number) => {
    e.stopPropagation();
    await archivePart({ variables: { id: partId } });
  };

  const selectedPart = useMemo(() => {
    return data?.parts.find(p => p.id === selectedPartId);
  }, [data, selectedPartId]);

  const onEditClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, partId: number) => {
    e.stopPropagation();
    setSelectedPartId(partId);
    setShowEditPartModal(true);
  };

  const onSubmit = async (partUpdates: PatchPartInput) => {
    if (selectedPart) {
      const edits = { ...partUpdates, id: selectedPart.id };
      await patchPart({ variables: { partInput: edits }  });
      setShowEditPartModal(false);
    }
  };

  const tableData = useMemo(() => {
    return orderBy(data?.parts.map(part => {
      const controlButtons = (
        <>
          <Button
            onClick={(e) => onEditClicked(e, part.id)}
            size="sm"
            title="Edit"
            variant="primary"
          >
            <FontAwesomeIcon icon="pen-to-square" />
          </Button>
          <Button
            disabled={part.archived}
            onClick={(e) => onArchiveClicked(e, part.id)}
            size="sm"
            title="Archive"
            variant="danger"
          >
            <FontAwesomeIcon icon="box-archive" />
          </Button>
        </>
      );

      return {
        ...part,
        controlButtons,
      };
    }), [sortKey], [sortDirection]);
  }, [data?.parts, sortKey, sortDirection]);

  const columns = [
    {
      title: 'Part Type',
      keyName: 'config.display_name',
      sortDirection: sortKey === 'config.display_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('config.display_name'),
        className: classNames(styles.clickable, styles.fixedColMed),
      }),
      filterable: true,
    },
    {
      title: 'Part Number',
      keyName: 'part_number',
      sortDirection: sortKey === 'part_number' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('part_number'),
        className: classNames(styles.clickable, styles.fixedColMed),
      }),
      filterable: true,
    },
    {
      title: 'Serial Number',
      keyName: 'serial_number',
      sortDirection: sortKey === 'serial_number' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('serial_number'),
        className: classNames(styles.clickable, styles.fixedColLarge),
      }),
      filterable: true,
    },
    {
      title: 'Exp. Date',
      keyName: 'exp_date',
      sortDirection: sortKey === 'exp_date' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('exp_date'),
        className: classNames(styles.clickable, styles.fixedColSmall),
      }),
      cellRenderer: (part: Part) => {
        const { exp_date: expDate } = part;
        let expDateClasses;

        if (expDate) {
          const expDateTime = new Date(expDate).getTime();
          const now = Date.now();
          const nearExpirationDate = new Date(expDate);
          nearExpirationDate.setMonth(nearExpirationDate.getMonth() - 1)

          if ((expDateTime - now) <= 0) {
            expDateClasses = classNames(styles.overLimit);
          } else if ((nearExpirationDate.getTime() - now) <= 0) {
            expDateClasses = classNames(styles.nearLimit);
          }
        }
        return (<span className={expDateClasses}>{expDate}</span>);
      },
    },
    {
      title: 'Mileage',
      keyName: 'mileage',
      sortDirection: sortKey === 'mileage' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('mileage'),
        className: classNames(styles.clickable, styles.fixedColMed),
      }),
      cellRenderer: (part: Part) => {
        const mileageLimit = get(mileageLimits, `${part.config.type_name}`);
        const mileageWarning = get(mileageWarnings, `${part.config.type_name}`);
        const mileagePercentage = mileageLimit && mileageLimit > 0 ? Math.floor((part.mileage / mileageLimit) * 100) + "%" : "";
        let mileageClasses;

        if (mileageLimit && (part.mileage >= mileageLimit)) {
          mileageClasses = classNames(styles.overLimit)
        } else if (!!mileageLimit && !!mileageWarning && (part.mileage >= (mileageLimit * (mileageWarning / 100)))) {
          mileageClasses = classNames(styles.nearLimit)
        }

        return (<span className={mileageClasses}>{part.mileage}{mileageLimit ? `/${mileageLimit} (${mileagePercentage})` : ""}</span>);
      },
    },
    {
      title: 'Archived',
      keyName: 'archived',
      sortDirection: sortKey === 'archived' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('archived'),
        className: classNames(styles.clickable, styles.fixedColSmall),
      }),
      cellRenderer: (part: Part) => {
        return <FormCheck type="checkbox" checked={part.archived} disabled />;
      },
      filterable: true,
      filterOptions: {
        selectOptions: [
          { key: 'true', value: 'Archived' },
          { key: 'false', value: 'Active' },
        ],
        resolveFilterValue: (filterKey: string) => {
          if (filterKey === 'true') return true;
          return false;
        },
      },
    },
    {
      title: '',
      keyName: 'controlButtons',
    },
  ];

  const onAddPartClicked = () => navigate(`/${seriesPath}/parts/create`);

  const onRowFunc = (record: InteractiveTableData) => ({
    onClick: () => navigate(`/${seriesPath}/parts/${record.id}`),
  });

  if (error) throw error;
  if (partConfigsError) throw partConfigsError;

  return (
    <>
      <div className={styles.header}>
        <h2>
          <img
            className='img-fluid'
            style={{ width: '10%', height: '10%' }}
            src={`${process.env.PUBLIC_URL}/img/GR-Badge.png`}
          />
          <span style={{ marginLeft: '5px', marginBottom: '-10px'}}>Part Lifing</span>
        </h2>
        <Button variant="primary" onClick={onAddPartClicked}>+ Add Part</Button>
      </div>
      <div className={classNames(styles.partsTableContainer)}>
        <InteractiveTable
          variant={darkMode ? 'dark' : 'light'}
          columns={columns}
          data={tableData}
          onRow={onRowFunc}
          loading={loading}
        />
      </div>
      <Modal
        size="sm"
        show={showEditPartModal}
        onHide={() => setShowEditPartModal(false)}
        aria-labelledby="edit-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="edit modal">
            Edit {selectedPart?.config.display_name}
          </Modal.Title>
        </Modal.Header>
          <Form
            className={classNames(styles.form, "mx-auto")}
            onSubmit={e => {
              handleSubmit(onSubmit)(e);
            }}
          >
            <Modal.Body>
              <Form.Group className="mb-3" controlId="formPartNumber">
                <Form.Label>Part Number</Form.Label>
                <Form.Control
                  defaultValue={selectedPart?.part_number}
                  size="sm"
                  {...register('part_number', { required: 'Part Number is required' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="part_number"
                  render={({ message }) => <p className="text-danger">{message}</p>}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSerialNumber">
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                  defaultValue={selectedPart?.serial_number}
                  size="sm"
                  {...register('serial_number', { required: 'Serial Number is required' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="serial_number"
                  render={({ message }) => <p className="text-danger">{message}</p>}
                />
              </Form.Group>
              {selectedPart?.config.expires && (
                <Form.Group className="mb-3" controlId="formExpirationDate">
                  <Form.Label>Expiration Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register('exp_date', {
                      required: 'Expiration Date is required',
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="exp_date"
                    render={({ message }) => <p className="text-danger">{message}</p>}
                  />
                </Form.Group>
              )}
              <Modal.Footer>
                <Button type="submit">{
                  patchLoading ? <Spinner animation="border" size="sm" /> : 'Save'
                }</Button>
              </Modal.Footer>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};