import { Navigate, Outlet, useRouteLoaderData } from 'react-router-dom';

interface Props {
  children?: JSX.Element;
  redirectPath?: string;
  permissions: string[];
}

const AuthRoute = (props: Props) => {
  const permissions = useRouteLoaderData('root') as string[];

  const isAllowed = props.permissions.every(p => permissions.includes(p));
  if (!isAllowed) {
    return <Navigate to={props.redirectPath ?? '/'} replace />;
  }

  return props.children ? props.children : <Outlet />;
};

export default AuthRoute;