import { useForm, useWatch } from 'react-hook-form';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import styles from "./index.module.css";
import toaster from 'helpers/toaster';

import {
  CreatePartInput,
  useCreatePartMutation,
  useGetPartConfigsBySeriesQuery,
  useGetPartsQuery,
} from 'graphql/generated/graphql';
import { useMemo } from 'react';
import { SERIES_MAP } from 'components/SeriesSelector';

export default () => {
  const { series: seriesPath } = useParams();
  if (!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const teams = useLoaderData() as string[];

  const navigate = useNavigate();
  const { data, error } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });
  const { refetch } = useGetPartsQuery();

  const { control, handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      teams: teams.length === 1 ? [teams[0]] : [],
      config_id: -1,
      part_number: '',
      serial_number: '',
      exp_date: null,
    },
  });

  const configId = useWatch({ control, name: 'config_id' });

  const serializedConfigs = useMemo(() => {
    return data?.partConfigs.filter(c => c.serializable) ?? [];
  }, [data]);

  const selectedConfig = useMemo(() => {
    return serializedConfigs.find(c => c.id === configId);
  }, [configId, serializedConfigs]);

  const [createPart, {
    loading: createLoading,
  }] = useCreatePartMutation({
    ignoreResults: true,
    onCompleted: () => {
      navigate(`/${seriesPath}/parts`);
      toaster.show({ bg: 'success' }, 'Success', 'Part successfully created');
      refetch();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error creating part: ${message}`);
    },
  });

  const onSubmit = async (data: CreatePartInput) => {
    await createPart({ variables: { partInput: data }});
  };

  if (error) throw error;

  return (
    <>
      <h2 className={classNames(styles.header, "mx-auto")}>Add Part</h2>
      <Form
        className={classNames(styles.form, "mx-auto")}
        onSubmit={e => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Form.Label>Part Type</Form.Label>
        <Form.Group className="mb-3">
          <Form.Select
            isInvalid={Boolean(errors.config_id)}
            size="sm"
            {...register('config_id', { required: true, valueAsNumber: true })}
          >
            <option />
            {serializedConfigs.map(config => (
              <option key={config.type_name} value={config.id}>{config.display_name}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Label>Part Number</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            isInvalid={Boolean(errors.part_number)}
            size="sm"
            {...register('part_number', { required: true })}
          />
        </Form.Group>
        <Form.Label>Serial Number</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            isInvalid={Boolean(errors.serial_number)}
            size="sm"
            {...register('serial_number', { required: true })}
          />
        </Form.Group>
        {selectedConfig?.expires && (
          <>
            <Form.Label>Expiration Date</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control
                isInvalid={Boolean(errors.exp_date)}
                size="sm"
                type="date"
                {...register('exp_date', { required: true })}
              />
            </Form.Group>
          </>
        )}
        <hr />
        <Form.Label>Teams</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            as="select"
            htmlSize={teams.length ?? 1}
            isInvalid={Boolean(errors.teams)}
            multiple
            {...register('teams', { required: true })}
          >
            {teams.map(t => <option key={`team-${t}`}>{t}</option>)}
          </Form.Control>
        </Form.Group>
        <hr />
        <div className={styles.actions}>
          <Button variant="secondary" onClick={() => navigate(`/${seriesPath}/parts`)}>Cancel</Button>
          <Button type="submit">{
            createLoading ? <Spinner animation="border" size="sm" /> : 'Save'
          }</Button>
        </div>
      </Form>
    </>
  )
};
