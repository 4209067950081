import React from 'react';
import * as ReactDOM from 'react-dom';
import { Toast, ToastProps } from 'react-bootstrap';
import ToastContainer, { ToastPosition } from 'react-bootstrap/esm/ToastContainer';

type IToastOptions = ToastProps & { header: string, body: string, key: string };

interface ToasterInstance {
  show(props: ToastProps, header: string, body: string, key?: string): string;

  dismiss(key: string): void;

  clear(): void;

  getToasts(): IToastOptions[];
}

interface IToasterProps {
  className?: string;
  autoFocus?: boolean;
  canEscapeKeyClear?: boolean;
  children?: React.ReactNode;
  position: ToastPosition;
  maxToasts?: number;
}

interface IToasterState {
  toasts: IToastOptions[];
}

class Toaster extends React.PureComponent<IToasterProps, IToasterState> implements ToasterInstance {
  public static defaultProps: IToasterProps = {
    autoFocus: false,
    canEscapeKeyClear: true,
    position: 'bottom-end',
  };

  public state: IToasterState = {
    toasts: [],
  };

  private toastId = 0;

  public static create(props?: IToasterProps, container = document.body): ToasterInstance {
    const containerElement = document.createElement('div');
    container.appendChild(containerElement);
    const toaster = ReactDOM.render<IToasterProps>(
      <Toaster {...props} />,
      containerElement,
    ) as Toaster;
    if (toaster == null) {
      throw new Error('Got `null` while creating Toaster');
    }
    return toaster;
  }

  public show(props: ToastProps, header: string, body: string, key?: string) {
    if (this.props.maxToasts) {
      this.dismissIfAtLimit();
    }

    const options = this.createToastOptions(props, header, body, key);
    if (key === undefined || this.isNewToastKey(key)) {
      this.setState(prevState => ({
        toasts: [options, ...prevState.toasts],
      }));
    } else {
      this.setState(prevState => ({
        toasts: prevState.toasts.map(t => (t.key === key ? options : t)),
      }));
    }

    return options.key;
  }

  public dismiss(key: string) {
    this.setState(({ toasts }) => ({
      toasts: toasts.filter(t => {
        const matchesKey = t.key === key;
        if (matchesKey) t.onClose?.();
        return !matchesKey;
      }),
    }));
  }

  public clear() {
    this.state.toasts.forEach(t => t.onClose?.());
    this.setState({ toasts: [] });
  }

  public getToasts() {
    return this.state.toasts;
  }

  public render() {
    return (
      <ToastContainer position={this.props.position}>
        {this.state.toasts.map(this.renderToast, this)}
        {this.props.children}
      </ToastContainer>
    )
  }

  private getDismissHandler = (toast: IToastOptions) => () => {
    this.dismiss(toast.key);
  };

  private renderToast = (toast: IToastOptions) => {
    return (
      <Toast
        {...toast}
        onClose={this.getDismissHandler(toast)}
        autohide
        delay={5000}
      >
        <Toast.Header>
          {toast.header}
        </Toast.Header>
        <Toast.Body>
          {toast.body}
        </Toast.Body>
      </Toast>
    );
  }

  private isNewToastKey(key: string) {
    return this.state.toasts.every(toast => toast.key !== key);
  }

  private createToastOptions(props: ToastProps, header: string, body: string, key = `toast-${this.toastId++}`) {
    return { ...props, header, body, key };
  }

  private dismissIfAtLimit() {
    if (this.state.toasts.length === this.props.maxToasts) {
      this.dismiss(this.state.toasts[this.state.toasts.length - 1].key!);
    }
  }
}

export default Toaster.create();
