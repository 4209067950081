export const millisecondsToTimeFormat = (input: number): string => {
  const minutes = Math.floor(input / 60000);
  const leftOverSeconds = input % 60000;
  const seconds = Math.floor(leftOverSeconds / 1000).toString().padStart(2, '0');
  const leftoverMilliseconds = leftOverSeconds % 1000;
  const milliseconds = (leftoverMilliseconds).toString().padStart(3, '0'); 
  return `${minutes > 0 ? minutes + ':' : ''}${seconds}${+milliseconds > 0 ? '.' + milliseconds : ''}`;
};

export const inputToMilliseconds = (input?: string | null): number | undefined => {
  if (!input) return;
  const splitMinutes = input?.split(':');
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;
  let secondsString = splitMinutes[0];

  if (splitMinutes.length === 2) {
      minutes = +splitMinutes[0] * 60000;
      secondsString = splitMinutes[1];
  }

  const splitSeconds = secondsString.split('.');
  seconds = +splitSeconds[0] * 1000;

  if (splitSeconds.length === 2) {
      milliseconds = +`0.${splitSeconds[1]}` * 1000;
  }

  return minutes + seconds + milliseconds;
};
