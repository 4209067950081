import { faCircleInfo, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { selectDarkMode, uiSlice } from 'reducers/ui';

import styles from './index.module.css';
import { Link } from 'react-router-dom';

export default () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);

  return (
    <ul className={styles.settingsList}>
      <li className={styles.settingsListItem}>
        <div>
          <FontAwesomeIcon
            className={styles.settingsIcon}
            icon={faLightbulb}
          />
          <span>Dark Mode</span>
        </div>
        <Form.Check
          type="switch"
          checked={darkMode}
          onChange={() => dispatch(uiSlice.actions.toggleDarkMode())}
        />
      </li>
      <li className={styles.settingsListItem}>
        <div>
          <FontAwesomeIcon
            className={styles.settingsIcon}
            icon={faCircleInfo}
          />
          <span>
            <Link className="nav-link" to={`/doc/help.pdf`} target="_blank">Help</Link>
          </span>
        </div>
      </li>
    </ul>
  );
};
