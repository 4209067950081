import { Parser } from '@json2csv/plainjs';
import { flatten } from '@json2csv/transforms';
import _ from 'lodash';

import { Outing, OutingDetails } from 'graphql/generated/graphql';

const omitDeep = (obj: Record<string, unknown>, keysToOmit: string | string[]) => {
  const keysToOmitIndex =  _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]);

  function omitFromObject(obj: Record<string, unknown>) {
    return _.transform(obj, (result: Record<string, unknown>, value: any, key: string) => { // eslint-disable-line
      if (key in keysToOmitIndex) return;
      result[key] = _.isObject(value) ? omitFromObject(value) : value;
    });
  }

  return omitFromObject(obj); // return the inner function result
}

const removeFields = (item: Outing) => {
  return omitDeep(item, ['__typename', 'id', 'series']);
};
const unwindParts = (item: Outing) => {
  const partsObj = item.parts?.reduce((acc, part) => {
    acc[part.config.type_name] = part.serial_number;
    return acc;
  }, {} as Record<string, string>) ?? [];

  const chassisParts = item.parts?.find(p => p.config.type_name === 'chassis')?.chassis_parts ?? [];
  const chassisPartsObj = chassisParts.reduce((acc, part) => {
    acc[part.config.type_name] = `${part.mileage} mi`;
    return acc;
  }, {} as Record<string, string>);

  return Object.assign({ ...item }, partsObj, chassisPartsObj);
};
// json2csv takes care of flattening other fields in the nested Outing
// structure, but `details.laps` is better served in a custom format
// of [lap #, lap time][]
const unwindLaps = (item: { 'details.laps': OutingDetails['laps'][number][] }) => {
  return {
    ...item,
    'details.laps': item['details.laps']
      .map((lap: OutingDetails['laps'][number]) => {
        return [lap.number, lap.lap_time];
      }),
  };
};

export const outings2CSV = (outings: Outing[]): string => {
  const parser = new Parser({
    transforms: [
      removeFields,
      unwindParts,
      flatten({
        paths: [
          'session', 'participant', 'settings', 'suspension', 'heights',
          'weights', 'tires', 'aero', 'details',
        ],
      }),
      unwindLaps,
    ],
  });
  return parser.parse(outings);
};

export const downloadCSV = (fileName: string, csv: string) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};