import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { graphqlLodash } from 'graphql-lodash';

import toaster from 'helpers/toaster';

import {
  apiBasePath,
  env,
} from '../config';

const httpLink = createHttpLink({
  uri: `${apiBasePath}/graphql`,
});

const getTokenFromCookies = () => {
  const cookies = document.cookie.split('; ').map(c => c.split('='));
  return cookies.find(([cookieName]) => cookieName === 'apex_platform_token')?.[1];
};

const authLink = setContext((_, { headers }) => {
  const token = getTokenFromCookies();
  if (!token) throw new Error('Not logged in');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logoutLink = onError((err: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (err.networkError?.result?.errors?.some((e: any) => e.extensions.code === 'UNAUTHENTICATED')) {
    toaster.show({ bg: 'error' }, 'Unauthenticated', 'Session expired. Please log in again.');
    // localStorage.removeItem('user_details');
    // setTimeout(() => window.location.reload(), 3000); // Give the user a second to see why they're being logged out.
  }
});

const client = new ApolloClient({
  link: from([
    authLink,
    logoutLink,
    // Strips the GraphQL internal __typename field from any outgoing payloads
    new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          (key: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            return key === '__typename' ? undefined : value;
          },
        );
      }
      return forward(operation);
    }),
    // Transform any queries using the GraphQL lodash decorator interface
    new ApolloLink((operation, forward) => {
      const { query, transform } = graphqlLodash(operation.query);
      operation.query = query;
      return forward(operation)
        .map(response => ({
          ...response,
          data: transform(response.data),
        }));
    }),
    httpLink,
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: env !== 'production',
});

export default client;
