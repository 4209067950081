import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { format } from 'date-fns';
import classnames from 'classnames';
import { orderBy, toString, partition } from 'lodash';

import {
  Outing,
  useCarsBySeriesQuery,
  useDriversQuery,
  useExportOutingsLazyQuery,
  useSeriesSummaryOutingsQuery,
} from 'graphql/generated/graphql';
import { SERIES_MAP } from 'components/SeriesSelector';
import { selectDarkMode } from 'reducers/ui';
import styles from './index.module.css';
import toaster from 'helpers/toaster';
import InteractiveTable, { InteractiveTableData } from 'components/InteractiveTable';
import { downloadCSV, outings2CSV } from 'helpers/export';

export default () => {
  const { series: seriesPath } = useParams();
  const navigate = useNavigate();
  if (!seriesPath) return null;

  const [sortKey, setSortKey] = useState('dateSort');
  const [sortDirection, setSortDirection] = useState('desc');

  const seriesName = SERIES_MAP[seriesPath];
  const darkMode = useSelector(selectDarkMode);

  const { data, error, loading } = useSeriesSummaryOutingsQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const [getFullOutings] = useExportOutingsLazyQuery();

  const { data: cars,
   } = useCarsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { data: drivers,
   } = useDriversQuery();

  const setSortBy = (fieldName: string) => {
    if (sortKey === fieldName) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortKey(fieldName);
      if (sortDirection === 'asc') setSortDirection('desc');
    }
  };

  const columns = [
    {
      title: 'Date',
      keyName: 'date',
      sortDirection: sortKey === 'dateSort' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('dateSort'),
        className: classnames(styles.clickable, styles.fixedColSmall),
      }),
      filterable: true,
    },
    {
      title: 'Track',
      keyName: 'track',
      sortDirection: sortKey === 'track' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('track'),
        className: classnames(styles.clickable, styles.fixedColMed),
      }),
      filterable: true,
    },
    {
      title: 'Event',
      keyName: 'event',
      sortDirection: sortKey === 'event' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('event'),
        className: classnames(styles.clickable, styles.fixedColMed),
      }),
      filterable: true,
    },
    {
      title: 'Session',
      keyName: 'session',
      sortDirection: sortKey === 'session' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('session'),
        className: classnames(styles.clickable, styles.fixedColMed),
      }),
      filterable: true,
    },
    {
      title: 'Driver',
      keyName: 'driver',
      sortDirection: sortKey === 'driver' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('driver'),
        className: classnames(styles.clickable, styles.fixedColSmall),
      }),
      filterable: true,
    },
    {
      title: 'Car',
      keyName: 'car',
      sortDirection: sortKey === 'carSort' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('carSort'),
        className: classnames(styles.clickable, styles.fixedColSmall),
      }),
      filterable: true,
    },
    {
      title: 'Run',
      keyName: 'run',
      sortDirection: sortKey === 'run' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('run'),
        className: classnames(styles.clickable, styles.fixedColSmall),
      }),
      filterable: true,
    },
    {
      title: 'Teams',
      keyName: 'teams',
      sortDirection: sortKey === 'teams' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('teams'),
        className: classnames(styles.clickable, styles.fixedColLarge),
      }),
      filterable: true,
    },
    {
      title: 'Description',
      keyName: 'description',
      filterable: false,
    },
  ];

  const formatDate = (date: string) => {
    let formattedDate = '';
    if(date) {
    const splitDate = date.split('-');
      if (splitDate.length === 3) {
          formattedDate = `${parseInt(splitDate[1])}/${parseInt(splitDate[2])}/${splitDate[0]}`;
      }
    }
    return formattedDate;
  };

  const tableData = useMemo(() => {
    if(!data) return undefined;
    const outings = data.outings.map(outing => {
      const isCustom = outing.custom_length != null && outing.custom_length >= 0;
      const customDate = outing.date ?? '';
      let date = outing.session.actual_start ?? outing.session.scheduled_start;
      if (date) date = format(new Date(date), 'M/d/yyyy');

      let dateSortFormat = isCustom ? outing.date : outing.session.actual_start ?? outing.session.scheduled_start;
      if (dateSortFormat) dateSortFormat = format(new Date(dateSortFormat), 'M/d/yyyy');
      const dateSort = dateSortFormat ? new Date(dateSortFormat) : null;

      const track = isCustom ? outing.track_name : outing.session.event.track_configuration.track.long_name;
      const event = isCustom ? 'Custom' : outing.session.event.name;
      const session = isCustom ? 'Custom' : outing.session.name;
      const driver = drivers?.drivers.find(d => d.id === outing.driver_id);
      const car = cars?.cars.find(c => c.id === outing.car_id)?.number;
      const carSort = car ? +car : null;

      return {
        id: outing.id,
        date: isCustom ? formatDate(customDate) : date,
        track,
        event,
        session,
        driver: driver ? driver?.last_name : '',
        car,
        run: toString(outing.run),
        teams: outing.teams?.join(', '),
        description: outing.description,
        dateSort,
        carSort
      };
    });
    
    // Split these lists up so nulls of whatever we are sorting are always at the bottom.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const splitList = partition(outings, (outing: any) => { return !!outing[sortKey] });
    const sorted = orderBy(splitList[0], [sortKey], [sortDirection]);
    return sorted.concat(splitList[1]);
  }, [data?.outings, [sortKey], sortDirection, cars, drivers]);

  const onRowFunc = (record: InteractiveTableData) => ({
    onClick: () => {
      navigate(`/${seriesPath}/outing/${record.id}`)
    },
  });
  const onAddOutingClicked = () => {
    navigate(`/${seriesPath}/outing/create`);
  };
  const onExportClicked = (type: string) => {
    if (type === 'csv') {
      getFullOutings({
        onCompleted: data => {
          const csv = outings2CSV(data.outings as Outing[]);
          const fileName = `${seriesName.toLowerCase()}_outings.csv`;
          downloadCSV(fileName, csv);
        },
        onError: ({ message }) => {
          toaster.show({ bg: 'danger' }, 'Error', `Error exporting outings: ${message}`);
        },
      });
    }
  };

  const variant = darkMode ? 'dark' : 'light';

  if (error) throw error;

  return (
    <>
      <div className={styles.header}>
        <h2>
          <img
            className='img-fluid'
            style={{ width: '10%', height: '10%' }}
            src={`${process.env.PUBLIC_URL}/img/GR-Badge.png`}
          />
          <span style={{ marginLeft: '5px', marginBottom: '-12px'}}>Outings Summary</span>
        </h2>

        <div className={styles.actions}>
          <DropdownButton
            title="Export"
            variant="secondary"
            disabled={data?.outings.length === 0}
          >
            <Dropdown.Item onClick={() => onExportClicked('csv')}>CSV</Dropdown.Item>
          </DropdownButton>
          <Button variant="primary" onClick={onAddOutingClicked}>+ Add Outing</Button>
        </div>
      </div>
      <div className={classnames(styles.outingsTableContainer)}>
        <InteractiveTable
          variant={variant}
          columns={columns}
          data={tableData}
          onRow={onRowFunc}
          loading={loading}
        />
      </div>
    </>
  );
};
