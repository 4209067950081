import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import client from './graphql';
import { store } from './store';
import routes from './routes';
import { env } from './config';

const basename = env === 'local' ? '/gr-setup' : '/';
const router = createBrowserRouter(routes, { basename });

export default () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ApolloProvider>
);
