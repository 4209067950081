import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';

import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

export const SERIES_MAP: { [key: string]: string } = {
  GRCS: 'I',
};

export default () => {
  const navigate = useNavigate();
  const darkMode = useSelector(selectDarkMode);
  const onSeriesClicked = useCallback((path: string) => {
    navigate(`/${path}`);
  }, [navigate]);

  return (
    <div
      className={styles.selectorContainer}
      style={{
        borderColor: darkMode ? '#FFF' : '#2C2C2C',
      }}
    >
      <h2
        className={styles.selectorHeading}
        style={{
          backgroundColor: darkMode ? '#2C2C2C' : '#FFF',
          color: darkMode ? '#FFF' : '#2C2C2C',
        }}
      >
        Select Series
      </h2>
      <div>
        {Object.keys(SERIES_MAP).map(path => (
          <Button
            className={styles.selection}
            key={path}
            onClick={() => onSeriesClicked(path)}
            variant={darkMode ? 'light' : 'dark'}
          >
            <Image
              fluid
              src={`${process.env.PUBLIC_URL}/img/${path}.png`}
            />
          </Button>
        ))}
      </div>
    </div>
  );
};
