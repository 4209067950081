import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { env } from 'config';
import ErrorBoundary from 'components/ErrorBoundary';
import SettingsMenu from 'components/SettingsMenu';
import NavigationMenu from 'components/NavigationMenu';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

export default () => {
  const darkMode = useSelector(selectDarkMode);

  return (
    <div>
      <PlatformToolbar
        icon={faScrewdriverWrench}
        name="GR Setup"
        href={env === 'local' ? '/gr-setup' : '/'}
        settingsComponent={<SettingsMenu />}
        navigationItems={<NavigationMenu />}
      />

      <main
        className={classNames({ [styles.dark]: darkMode, dark: darkMode }, styles.content)}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};
