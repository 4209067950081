import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap';
import classNames from 'classnames';

import { Outing, useOutingQuery } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import RunView from './RunView';
import BuildView from './BuildView';

import styles from '../index.module.css';
import { downloadCSV, outings2CSV } from 'helpers/export';
import { format } from 'date-fns';

export default () => {
  const { series: seriesPath, outingId } = useParams();
  if (!seriesPath || !outingId) return null;

  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState(state?.tabKey ?? 'run');

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  const darkMode = useSelector(selectDarkMode);

  const { data, error } = useOutingQuery({
    variables: {
      outingId: Number.parseInt(outingId, 10),
    },
  });

  const onExportClicked = (type: string) => {
    if (!data?.outing) return;

    if (type === 'csv') {
      const csv = outings2CSV([data.outing as Outing]);

      let date = data.outing.session.actual_start ?? data.outing.session.scheduled_start;
      if (date) date = format(new Date(date), 'M-d-yyyy');
      const { name: eventName } = data.outing.session.event;
      const { name: sessionName } = data.outing.session;
      const { run } = data.outing;
      const car = data.outing.car ? data.outing.car?.number : "NA";
      const fileName = `${date}_${eventName}_${sessionName}_R${run}_#${car}.csv`.toLowerCase();

      downloadCSV(fileName, csv);
    }
  };
  const onEditClicked = () => {
    navigate(`/${seriesPath}/outing/edit/${outingId}`, { state: { tabKey } });
  }

  const onCopyClicked = async () => {
    if(!data?.outing) return;
    navigate(`/${seriesPath}/outing/create?copy=${data?.outing.id}`);
  };

  if (error) {
    throw error;
  }

  return (
    <>
      <div className={styles.actions}>
        <DropdownButton
          title="Export"
          variant="secondary"
        >
          <Dropdown.Item onClick={() => onExportClicked('csv')}>CSV</Dropdown.Item>
        </DropdownButton>
        <Button onClick={() => onCopyClicked()} type="submit">{
          'Copy'
        }</Button>
        <Button onClick={onEditClicked}>Edit</Button>
      </div>
      <h2>
        <img
          className='img-fluid'
          style={{ width: '82px', height: '44px' }}
          src={`${process.env.PUBLIC_URL}/img/GR-Badge.png`}
        />
        <span style={{ marginLeft: '5px', marginBottom: '-12px'}}>View Outing</span>
      </h2>
      <Tabs
        className={classNames(styles.viewTabsContainer, { dark: darkMode })}
        activeKey={tabKey}
        onSelect={k => setTabKey(k!)}
      >
        <Tab
          eventKey="run"
          title="Run"
          tabClassName={classNames(styles.viewTab, { dark: darkMode })}
        >
          <RunView outing={data?.outing as Outing} />
        </Tab>
        <Tab
          eventKey="build"
          title="Build"
          tabClassName={classNames(styles.viewTab, { dark: darkMode })}
        >
          <BuildView outing={data?.outing as Outing} />
        </Tab>
      </Tabs>
    </>
  );
};