import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';

import { Outing, useGetPartConfigsBySeriesQuery } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import styles from "../index.module.css";
import { SERIES_MAP } from 'components/SeriesSelector';

interface Props {
  outing?: Outing,
}

export default (props: Props) => {
  const { series: seriesPath, outingId } = useParams();
  if (!seriesPath || !outingId) return null;
  const seriesName = SERIES_MAP[seriesPath];
  
  const darkMode = useSelector(selectDarkMode);
  const { data: partConfigsQuery, error: partConfigsError } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const {
    parts,
  } = props?.outing ?? {};

  const chassisParts = useMemo(() => {
    const chassisPart = parts?.find(p => p.config?.type_name === 'chassis');
    return chassisPart?.chassis_parts ?? [];
  }, [parts]);

  const findPartOfType = useCallback((typeName: string, position?: string) => {
    return parts?.find(p => p.config.type_name === typeName && (!position || p.position === position));
  }, [parts]);

  const renderPartInput = (partType: string, position?: string) => {
    const config = partConfigsQuery?.partConfigs.find(c => c.type_name === partType);
    const part = findPartOfType(partType, position);

    const partControl = <Form.Control readOnly size="sm" value={part?.serial_number || ''} />;
    if (!config?.expires) return partControl;

    return (
      <div className={styles.expiringPart}>
        {partControl}
        <Form.Control
          readOnly
          size="sm"
          type="date"
          value={part?.exp_date ?? ''}
        />
      </div>
    );
  };

  const renderChassisPart = (partType: string) => {
    const chassisPart = chassisParts.find(p => p.config.type_name === partType);
    return (
      <Form.Control
        size="sm"
        disabled
        value={!_.isNil(chassisPart?.mileage) ? `${chassisPart!.mileage.toFixed(2)} mi` : '- mi'}
      />
    );
  };

  if (partConfigsError) throw partConfigsError;

  return (
    <>
      <Form className={classNames({ [styles.dark]: darkMode }, styles.buildForm)}>
        <div className={styles.colContainer}>
          <div>
            <fieldset className={classNames(styles.fieldset, styles.body)} disabled>
              <h3>Body</h3>
              <div className={classNames(styles.labelCell, styles.chassisLabel)}>Chassis</div>
              <Form.Group className={classNames(styles.inputCell, styles.chassis)}>
                {renderPartInput('chassis')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fuelCellBladderLabel)}>Fuel Cell Bladder</div>
              <Form.Group className={classNames(styles.inputCell, styles.fuelCellBladder)}>
                {renderPartInput('fuel_cell_bladder')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.fireBottleLabel)}>Fire Bottle</div>
              <Form.Group className={classNames(styles.inputCell, styles.fireBottle)}>
                {renderPartInput('fire_bottle')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.seatLabel)}>Seat</div>
              <Form.Group className={classNames(styles.inputCell, styles.seat)}>
                {renderPartInput('seat')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.electronics)} disabled>
              <h3>Electronics</h3>
              <div className={classNames(styles.labelCell, styles.ms6Label)}>MS6</div>
              <Form.Group className={classNames(styles.inputCell, styles.ms6)}>
                {renderPartInput('ms6')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.pbx90Label)}>PBX90</div>
              <Form.Group className={classNames(styles.inputCell, styles.pbx90)}>
                {renderPartInput('pbx90')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.ddu11DashLabel)}>DDU11 Dash</div>
              <Form.Group className={classNames(styles.inputCell, styles.ddu11Dash)}>
                {renderPartInput('ddu11_dash')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.lteTelemetryLabel)}>LTE Telemetry</div>
              <Form.Group className={classNames(styles.inputCell, styles.lteTelemetry)}>
                {renderPartInput('lte65_telemetry')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.absLabel)}>ABS</div>
              <Form.Group className={classNames(styles.inputCell, styles.abs)}>
                {renderPartInput('abs')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.canPanelLabel)}>CAN Panel</div>
              <Form.Group className={classNames(styles.inputCell, styles.canPanel)}>
                {renderPartInput('can_panel')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.mm510Label)}>MM5.10</div>
              <Form.Group className={classNames(styles.inputCell, styles.mm510)}>
                {renderPartInput('mm510')}
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.powertrain)} disabled>
              <h3>Powertrain</h3>
              <div className={classNames(styles.labelCell, styles.engineLabel)}>Engine</div>
              <Form.Group className={classNames(styles.inputCell, styles.engine)}>
                {renderPartInput('engine')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.engineOilLabel)}>Engine Oil/Filter</div>
              <Form.Group className={classNames(styles.inputCell, styles.engineOilFilter)}>
                {renderChassisPart('engine_oil_filter')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.engineCoolantLabel)}>Engine Coolant</div>
              <Form.Group className={classNames(styles.inputCell, styles.engineCoolant)}>
                {renderChassisPart('engine_coolant')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.sparkPlugsLabel)}>Spark Plugs</div>
              <Form.Group className={classNames(styles.inputCell, styles.sparkPlugs)}>
                {renderChassisPart('spark_plugs')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.airFilterLabel)}>Air Filter</div>
              <Form.Group className={classNames(styles.inputCell, styles.airFilter)}>
                {renderPartInput('air_filter')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.lambdaSensorLabel)}>Lambda Sensor</div>
              <Form.Group className={classNames(styles.inputCell, styles.lambdaSensor)}>
                {renderPartInput('lambda_sensor')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.brakes)} disabled>
              <h3>Brakes</h3>
              <div className={classNames(styles.labelCell, styles.calipersFrontLabel)}>Calipers Front Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.calipersLF)}>
                {renderChassisPart('caliper_left_front')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.calipersRF)}>
                {renderChassisPart('caliper_right_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.calipersRearLabel)}>Calipers Rear Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.calipersLR)}>
                {renderChassisPart('caliper_left_rear')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.calipersRR)}>
                {renderChassisPart('caliper_right_rear')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeFluidLabel)}>Brake Fluid</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeFluid)}>
                {renderChassisPart('brake_fluid')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakePadsFrontLabel)}>Brake Pads Front</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakePadsFront)}>
                {renderChassisPart('brake_pads_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakePadsRearLabel)}>Brake Pads Rear</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakePadsRear)}>
                {renderChassisPart('brake_pads_rear')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeRotorsFrontLabel)}>Brake Rotors Front</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeRotorsFront)}>
                {renderChassisPart('brake_rotors_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.brakeRotorsRearLabel)}>Brake Rotors Rear</div>
              <Form.Group className={classNames(styles.inputCell, styles.brakeRotorsRear)}>
                {renderChassisPart('brake_rotors_rear')}
              </Form.Group>
            </fieldset>
          </div>

          <div>
            <fieldset className={classNames(styles.fieldset, styles.driveline)} disabled>
              <h3>Driveline</h3>
              <div className={classNames(styles.labelCell, styles.transmissionLabel)}>Transmission</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmission)}>
                {renderPartInput('transmission')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.transmissionServiceLabel)}>Transmission Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmissionService)}>
                {renderChassisPart('transmission_service')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.transmissionOilLabel)}>Transmission Oil</div>
              <Form.Group className={classNames(styles.inputCell, styles.transmissionOil)}>
                {renderChassisPart('transmission_oil')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.differentialOilFilterCleanLabel)}>Differential Oil/Filter Clean</div>
              <Form.Group className={classNames(styles.inputCell, styles.differentialOilFilterClean)}>
                {renderChassisPart('differential_oil_filter_clean')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.propshaftUJointLabel)}>Propshaft U-Joint Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.propshaftUJoint)}>
                {renderChassisPart('propshaft_ujoint')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.axleLRLabel)}>Axle LR</div>
              <Form.Group className={classNames(styles.inputCell, styles.axleLR)}>
                {renderPartInput('axle_lr')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.axleRRLabel)}>Axle RR</div>
              <Form.Group className={classNames(styles.inputCell, styles.axleRR)}>
                {renderPartInput('axle_rr')}
              </Form.Group>
            </fieldset>

            <fieldset className={classNames(styles.fieldset, styles.buildSuspension)} disabled>
              <h3>Suspension</h3>
              <div className={classNames(styles.labelCell, styles.shockFrontLabel)}>Shock/Strut Front Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.shockLF)}>
                {renderChassisPart('shock_left_front')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.shockRF)}>
                {renderChassisPart('shock_right_front')}
              </Form.Group>
              <div className={classNames(styles.labelCell, styles.shockRearLabel)}>Shock/Strut Rear Service</div>
              <Form.Group className={classNames(styles.inputCell, styles.shockLR)}>
                {renderChassisPart('shock_left_rear')}
              </Form.Group>
              <Form.Group className={classNames(styles.inputCell, styles.shockRR)}>
                {renderChassisPart('shock_right_rear')}
              </Form.Group>
            </fieldset>
          </div>
        </div>
      </Form>
    </>
  );
};