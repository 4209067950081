import { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import classnames from 'classnames';
import { toString, orderBy } from 'lodash'

import { useGetOutingsByPartQuery } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import InteractiveTable, { InteractiveTableData } from 'components/InteractiveTable';
import styles from './index.module.css';

export default () => {
  const { series: seriesPath, partId } = useParams();
  const navigate = useNavigate();
  if (!seriesPath || !partId) return null;

  const { data, error, loading } = useGetOutingsByPartQuery({
    variables: {
      partId: parseInt(partId),
    },
  });

  const columns = [
    {
      title: 'Date',
      keyName: 'date',
      filterable: false,
    },
    {
      title: 'Track',
      keyName: 'track',
      filterable: false,
    },
    {
      title: 'Event',
      keyName: 'event',
      filterable: false,
    },
    {
      title: 'Session',
      keyName: 'session',
      filterable: false,
    },
    {
      title: 'Driver',
      keyName: 'driver',
      filterable: false,
    },
    {
      title: 'Car',
      keyName: 'car',
      filterable: false,
    },
    {
      title: 'Run',
      keyName: 'run',
      filterable: false,
    },
    {
      title: 'Description',
      keyName: 'description',
      filterable: false,
    },
  ];

  const tableData = useMemo(() => {
    return orderBy(data?.partOutings.map(outing => {
      let date = outing.session.actual_start ?? outing.session.scheduled_start;
      if (date) date = format(new Date(date), 'M/d/yyyy');

      return {
        id: outing.id,
        date,
        track: outing.session.event.track_configuration.track.long_name,
        event: outing.session.event.name,
        session: outing.session.name,
        driver: outing.driver?.last_name || "",
        car: outing.car?.number || "",
        run: toString(outing.run),
        description: outing.description,
      };
    }), ['date'], ['asc']);
  }, [data?.partOutings]);

  const onRowFunc = (record: InteractiveTableData) => ({
    onClick: () => {
      navigate(`/${seriesPath}/outing/${record.id}`)
    },
  });

  const darkMode = useSelector(selectDarkMode);
  const variant = darkMode ? 'dark' : 'light';

  if (error) throw error;

  return (
    <>
      <h2>Outings Associated to Part</h2>
      <div className={classnames(styles.outingsTableContainer)}>
        <InteractiveTable
          variant={variant}
          columns={columns}
          data={tableData}
          onRow={onRowFunc}
          loading={loading}
        />
      </div>
    </>
  );
};
