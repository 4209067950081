import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useLoaderData, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tab, Tabs, Button, Spinner, Form } from 'react-bootstrap';
import classNames from 'classnames';

import {
  CreateOutingInput,
  UpdateOutingInput,
  TireType,
  useCreateOutingMutation,
  useEditOutingQuery,
  useUpdateOutingMutation,
  useSeriesSummaryOutingsQuery
} from 'graphql/generated/graphql';
import toaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import RunView from './RunView';
import BuildView from './BuildView';
import { pick } from 'lodash';

import styles from '../index.module.css';
import { inputToMilliseconds, millisecondsToTimeFormat } from 'helpers/timeFormat';
import { SERIES_MAP } from 'components/SeriesSelector';

export const defaultValues = {
  description: '',
  teams: '',
  series_id: '',
  session_id: '',
  participant_id: '',
  run: undefined,
  engineer: '',
  weights: {
    weight_lf: undefined,
    weight_rf: undefined,
    weight_lr: undefined,
    weight_rr: undefined,
    fuel: undefined,
    driver: undefined,
  },
  heights: {
    fender_heights_lf: '',
    fender_heights_rf: '',
    frame_heights_lf: '',
    frame_heights_rf: '',
    fender_heights_lr: '',
    fender_heights_rr: '',
    frame_heights_lr: '',
    frame_heights_rr: '',
  },
  settings: {
    camber_lf: undefined,
    camber_rf: undefined,
    toe_lf: '',
    toe_rf: '',
    camber_lr: undefined,
    camber_rr: undefined,
    toe_lr: '',
    toe_rr: '',
    abs_map: undefined,
    tc_map: undefined,
  },
  suspension: {
    spring_lf: undefined,
    spring_rf: undefined,
    spring_lr: undefined,
    spring_rr: undefined,
    perch_gap_lf: '',
    perch_gap_rf: '',
    perch_gap_lr: '',
    perch_gap_rr: '',
    compression_clicks_lf: undefined,
    compression_clicks_rf: undefined,
    compression_clicks_lr: undefined,
    compression_clicks_rr: undefined,
    rebound_clicks_lf: undefined,
    rebound_clicks_rf: undefined,
    rebound_clicks_lr: undefined,
    rebound_clicks_rr: undefined,
    camber_letter_lf: '',
    camber_letter_lr: '',
    sway_bar_hole_lf: undefined,
    sway_bar_hole_rf: undefined,
    sway_bar_hole_lr: undefined,
    sway_bar_hole_rr: undefined,
  },
  tires: {
    tire_type: TireType.Dry,
    set: undefined,
    pressure_lf: undefined,
    pressure_rf: undefined,
    pressure_lr: undefined,
    pressure_rr: undefined,
  },
  aero: {
    splitter_height_left: '',
    splitter_height_mid: '',
    splitter_height_right: '',
    spoiler_angle: undefined,
  },
  details: {
    laps: [{ number: 1, lap_time: undefined }],
    changes: '',
    driver_comments: '',
    max_coolant_temp: undefined,
    max_engine_oil_temp: undefined,
    max_trans_fluid_temp: undefined,
    max_diff_oil_temp: undefined,
    tire_pressure_lf: undefined,
    tire_pressure_lr: undefined,
    tire_pressure_rf: undefined,
    tire_pressure_rr: undefined,
    rotor_temp_lf: undefined,
    rotor_temp_lr: undefined,
    rotor_temp_rf: undefined,
    rotor_temp_rr: undefined,
    caliper_temp_lf: undefined,
    caliper_temp_lr: undefined,
    caliper_temp_rf: undefined,
    caliper_temp_rr: undefined,
    tire_temp_lf_outer: undefined,
    tire_temp_lf_middle: undefined,
    tire_temp_lf_inner: undefined,
    tire_temp_lr_outer: undefined,
    tire_temp_lr_middle: undefined,
    tire_temp_lr_inner: undefined,
    tire_temp_rf_outer: undefined,
    tire_temp_rf_middle: undefined,
    tire_temp_rf_inner: undefined,
    tire_temp_rr_outer: undefined,
    tire_temp_rr_middle: undefined,
    tire_temp_rr_inner: undefined,
    fuel_added: undefined,
  },
  mileage: 0,
  ms6_config: '',
  pbx_config: '',
  dash_config: '',
  engine_coolant: '',
  trans_fluid: '',
  differential_fluid: '',
  brake_fluid: '',
  fuel: '',
  parts: [],
  custom_length: null,
  time: '',
  weather: undefined,
  temp: undefined,
  track_temp: undefined,
}

export default () => {
  const { series: seriesPath, outingId } = useParams();
  if (!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];

  const teams = useLoaderData() as string[];

  const { state } = useLocation();
  const navigate = useNavigate();
  const darkMode = useSelector(selectDarkMode);

  const [isCustom, setIsCustom] = useState(false);
  const [eventId, setEventId] = useState('');
  const [tabKey, setTabKey] = useState(state?.tabKey ?? 'run');

  const [searchParams ] = useSearchParams();
  const copyOutingId = Number.parseInt(searchParams.get("copy") ?? '', 10);

  const { refetch: refetchOutings } = useSeriesSummaryOutingsQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const methods = useForm<CreateOutingInput | UpdateOutingInput>({
    defaultValues: { 
      ...defaultValues,
      teams: teams.length === 1 ? [teams[0]] : [],
    },
  });

  const outingIdNum = Number.parseInt(outingId ?? '', 10);

  useEditOutingQuery({
    variables: {
      outingId: outingIdNum || copyOutingId,
    },
    skip: !outingId && !copyOutingId,
    onCompleted: ({ outing }) => {
      setEventId(outing.session.event.id);
      const values = {
        ...outing,
        session: undefined,
      } as unknown as UpdateOutingInput;
      if (!values.details?.laps.length) {
        values.details?.laps.push({ number: 1, lap_time: null, formatted_time: null });
      } else {
        values.details.laps = values.details.laps.map(l => {
          return { ...l, lap_time: inputToMilliseconds(l.formatted_time)};
        });
      }
      if (outing.custom_length != null && outing.custom_length >= 0) {
        setIsCustom(true);
        setEventId('custom');
      }
      if (copyOutingId) {
        const copiedOuting: CreateOutingInput = { 
          ...defaultValues,
          ...pick(outing, [
            'description',
            'teams',
            'weights',
            'heights',
            'settings',
            'suspension',
            'tires',
            'aero',
            'run',
            'track_name',
            'date',
            'custom_length'
          ]),
          parts: outing.parts,
          series_id: outing.series_id,
          car_id: outing.car_id,
          driver_id: outing.driver_id,
          session_id: outing.session_id,
        };
        methods.reset(copiedOuting);
      } else {
        methods.reset(values);
      }
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error getting outing: ${message}`);
    },
  });

  const [createOuting, {
    loading: createLoading,
  }] = useCreateOutingMutation({
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({ bg: 'success' }, 'Success', 'Outing successfully created');
      refetchOutings();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error creating outing: ${message}`);
    },
  });

  const [updateOuting, {
    loading: updateLoading,
  }] = useUpdateOutingMutation({
    ignoreResults: true,
    onCompleted: () => {
      navigate(`/${seriesPath}/outing/${outingId}`, { state: { tabKey } });
      toaster.show({ bg: 'success' }, 'Success', 'Outing successfully updated');
      refetchOutings();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error updating outing: ${message}`);
    },
  });

  const onCancelClicked = () => {
    if (outingId) navigate(`/${seriesPath}/outing/${outingId}`, { state: { tabKey } });
    else navigate(`/${seriesPath}`, { state: { tabKey } });
  };

  

  const onValid = async (data: CreateOutingInput | UpdateOutingInput) => {
    const detailsLastLap = data.details?.laps[data.details?.laps.length - 1];
    if (detailsLastLap && !detailsLastLap.lap_time) {
      data.details?.laps.pop();
    }

    if (data.details && data.details.laps.length > 0) {
      data.details.laps = data.details.laps.map(l => {
        if (l.formatted_time) {
          const milliseconds = inputToMilliseconds(l.formatted_time);
          l.lap_time = milliseconds;
          l.formatted_time = milliseconds ? millisecondsToTimeFormat(milliseconds) : l.formatted_time;
        } 
        return l;
      });
    }
    
    if (data.custom_length == null || data.custom_length < 0) {
      data.custom_length = null;
    }

    if (outingId) await updateOuting({ variables: { outingInput: data as UpdateOutingInput }});
    else {
      const outing = await createOuting({ variables: { outingInput: data as CreateOutingInput }});
      navigate(`/${seriesPath}/outing/${outing.data?.outing?.id}`, { state: { tabKey } });
    }
  };
  const onInvalid = () => {
    // ???
  };

  return (
    <>
      <div className={classNames(styles.actions, styles.createActions)}>
        <Button variant="secondary" onClick={onCancelClicked}>Cancel</Button>
        <Button onClick={e => methods.handleSubmit(onValid, onInvalid)(e)} type="submit">{
          createLoading ?? updateLoading ? <Spinner animation="border" size="sm" /> : 'Save'
        }</Button>
      </div>
      <h2>
        <img
          className='img-fluid'
          style={{ width: '82px', height: '44px' }}
          src={`${process.env.PUBLIC_URL}/img/GR-Badge.png`}
        />
        <span style={{ marginLeft: '5px', marginBottom: '-12px'}}>{outingId ? 'Edit': 'Add'} Outing</span>
      </h2>
      <FormProvider {...methods}>
        <Form name="outingCreate" onSubmit={methods.handleSubmit(onValid, onInvalid)}>
          <Tabs
            className={classNames(styles.viewTabsContainer, { dark: darkMode })}
            activeKey={tabKey}
            onSelect={k => setTabKey(k!)}
          >
            <Tab
              eventKey="run"
              title="Run"
              tabClassName={
                classNames(styles.viewTab, { dark: darkMode })
              }
            >
              <RunView 
                eventId={eventId} 
                isCustom={isCustom} 
                setIsCustom={setIsCustom} 
              />
            </Tab>
            <Tab
              eventKey="build"
              title="Build"
              tabClassName={
                classNames(styles.viewTab, { dark: darkMode })
              }
            >
              <BuildView />
            </Tab>
          </Tabs>


        </Form>
      </FormProvider>
    </>
  );
};
