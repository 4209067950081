import { useMemo, useState } from 'react';
import { Form, Table, Modal, DropdownButton, Dropdown, ButtonGroup, Button, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { filter, get, omit, toPairs, uniq, find, has } from 'lodash';

import styles from './index.module.css';

export interface InteractiveTableData {
  id: number;
  [key: string]: unknown;
}

interface InteractiveTableOnHeader {
  onClick: () => void;
  className: string;
}

interface InteractiveTableFilterOption {
  key: string;
  value: string;
}

interface ResolveFilterValueFn {
  (filterKey: string): unknown;
}

interface InteractiveTableColumnFilterOptions {
  selectOptions: InteractiveTableFilterOption[];
  resolveFilterValue?: ResolveFilterValueFn; 
}

interface InteractiveTableColumn {
  title: string;
  keyName: string;
  filterable?: boolean;
  filterOptions?: InteractiveTableColumnFilterOptions,
  sortDirection?: string | boolean;
  onHeader?: () => InteractiveTableOnHeader;
  cellRenderer?: (value: any) => JSX.Element; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface InteractiveTableProps {
  variant: string;
  columns: InteractiveTableColumn[];
  data: InteractiveTableData[];
  onRow?: (record: InteractiveTableData) => { onClick: () => void };
  loading: boolean;
}

export default (props: InteractiveTableProps) => {
  const { variant, columns, data, onRow, loading } = props;

  const [currentFilter, setCurrentFilter] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const filteredData = useMemo(() => {
    const filters: string[][] = toPairs(appliedFilters);
    const filtered = filter(data, (d: InteractiveTableData) => {
      return filters.every(f => get(d, f[0]) === f[1]);
    });
    return filtered;
  }, [data, appliedFilters])

  const pageStart = useMemo(() => {
    return (itemsPerPage * page) || 1;
  }, [itemsPerPage, page]);
  const pageEnd = useMemo(() => {
    const pageEnd = itemsPerPage * (page + 1);
    return pageEnd > filteredData.length ? filteredData.length : pageEnd;
  }, [filteredData.length, itemsPerPage, page]);
  const pageNextDisabled = useMemo(() => pageEnd === filteredData.length, [pageEnd, filteredData.length]);
  const pagePrevDisabled = useMemo(() => pageStart === 1, [pageStart]);

  const rowData = useMemo(() => {
    if (filteredData.length > itemsPerPage) {
      return filteredData.slice(itemsPerPage * page, itemsPerPage * (page + 1));
    }
    return filteredData;
  }, [filteredData, itemsPerPage, page]);

  const onFilterClicked = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, keyName: string) => {
    e.stopPropagation();
    setCurrentFilter(keyName);
    setShowFilterModal(true);
  };
  const onItemsPerPageClicked = (numItems: number) => {
    setPage(0);
    setItemsPerPage(numItems);
  };
  const onPageNextClicked = () => {
    setPage(page + 1);
  };
  const onPagePrevClicked = () => {
    setPage(page - 1);
  };

  const handleApplyFilter = (val: string, resolveFilterValue?: ResolveFilterValueFn) => {
    if (val !== 'any') {
      setAppliedFilters({
        ...appliedFilters,
        [currentFilter]: resolveFilterValue ? resolveFilterValue(val) : val,
      });
    } else {
      setAppliedFilters(omit(appliedFilters, [currentFilter]));
    }
  };

  const getSortIcon = (sortDirection: string | boolean) => {
    if (sortDirection === 'asc') return (<FontAwesomeIcon icon={faArrowUp} />);
    if (sortDirection === 'desc') return (<FontAwesomeIcon icon={faArrowDown} />);
    return null;
  };

  const renderFilterOptions = () => {
    if (!currentFilter) return null;

    let filterOptions: InteractiveTableFilterOption[];

    const customFilterOptions = get(find(columns, (col: InteractiveTableColumn) => (col.keyName === currentFilter)), 'filterOptions');

    if (customFilterOptions) {
      filterOptions = customFilterOptions.selectOptions;
    } else {
      filterOptions = uniq(data.map((o: InteractiveTableData) => get(o, `${currentFilter}`))).sort().map((option: string) => {
        return {
          key: option,
          value: option,
        };
      });
    }

    const appliedFilter = get(appliedFilters, `${currentFilter}`, 'any');

    return (
      <>
        <Form.Select value={appliedFilter} onChange={(e) => handleApplyFilter(e.target.value, customFilterOptions?.resolveFilterValue)}>
          <option key="any" value="any">Any</option>
          {filterOptions.map((filterOption: InteractiveTableFilterOption) => {
            return (
              <option key={filterOption.key} value={filterOption.key}>
                {filterOption.value}
              </option>
            );
          })}
        </Form.Select>
      </>
    );
  };

  const renderTableHeaderCell = (column: InteractiveTableColumn) => {
    const onHeader = column.onHeader || (() => null);
    const colSortDirection = column.sortDirection || '';
    const activeColFilter = has(appliedFilters, `${column.keyName}`) ? 'text-primary' : '';

    return (
      <th
        {...onHeader()}
        key={`interative-table-${column.title}`}
      >
        <div className={classnames(styles.tableHeader)}>
          <span className={colSortDirection ? 'text-primary' : ''}>
            {column.title} {getSortIcon(colSortDirection)}
          </span>
          {column.filterable ? (
            <FontAwesomeIcon
              className={classnames(styles.filterIcon, activeColFilter)}
              icon={faFilter}
              onClick={(e) => onFilterClicked(e, column.keyName)}
            />
          ) : null}
        </div>
      </th>
    );
  };

  const renderTableRow = (item: InteractiveTableData) => (
    <tr
      key={`interactive-table-${item.id}`}
      {...onRow?.(item) ?? {}}
    >
      {columns.map(({ keyName, title, cellRenderer }) => {
        const datum = get(item, keyName);
        return (
          <td key={`row-${item.id}-${title}`} title={typeof datum === 'string' ? datum : ''}>
            {cellRenderer?.(item) ?? datum}
          </td>
        );
      })}
    </tr>
  );

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <Table className={styles.table} striped bordered hover variant={variant} size="sm">
        <thead>
          <tr>
            {columns.map(renderTableHeaderCell)}
          </tr>
        </thead>
        <tbody>
          {rowData.map(renderTableRow)}
        </tbody>
      </Table>
      <div className={styles.tableFooter}>
        <div className={styles.rowsPerPage}>
          <span>Rows per page:</span>
          <DropdownButton size="sm" title={itemsPerPage} variant="secondary">
            <Dropdown.Item onClick={() => onItemsPerPageClicked(10)}>10</Dropdown.Item>
            <Dropdown.Item onClick={() => onItemsPerPageClicked(25)}>25</Dropdown.Item>
            <Dropdown.Item onClick={() => onItemsPerPageClicked(50)}>50</Dropdown.Item>
            <Dropdown.Item onClick={() => onItemsPerPageClicked(100)}>100</Dropdown.Item>
          </DropdownButton>
        </div>
        <div>
          <span>{pageStart} - {pageEnd} of {filteredData.length}</span>
        </div>
        <div>
          <ButtonGroup>
            <Button
              disabled={pagePrevDisabled}
              onClick={onPagePrevClicked}
              size="sm"
              variant="secondary"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
              disabled={pageNextDisabled}
              onClick={onPageNextClicked}
              size="sm"
              variant="secondary"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Modal
        size="sm"
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        aria-labelledby="filter-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="filter modal">
            Filter {columns.find(c => c.keyName === currentFilter)?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderFilterOptions()}
        </Modal.Body>
      </Modal>
    </>
  );
};
