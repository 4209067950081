import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { toNumber, snakeCase } from 'lodash'

import styles from "./index.module.css";
import toaster from 'helpers/toaster';

import {
  CreatePartConfigInput,
  useCreatePartConfigMutation,
  useEventsBySeriesQuery,
  useGetPartConfigsBySeriesQuery,
} from 'graphql/generated/graphql';
import { SERIES_MAP } from 'components/SeriesSelector';

export default () => {
  const { series: seriesPath } = useParams();
  if(!seriesPath) return null;
  const seriesName = SERIES_MAP[seriesPath];
  const navigate = useNavigate();

  const { handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      display_name: '',
      type_name: '',
      expires: false,
      mileage_limit: null,
      mileage_warning_percentage: null,
      serializable: false,
    },
  });

  const { refetch } = useGetPartConfigsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const { data: events } = useEventsBySeriesQuery({
    variables: {
      seriesIdentifier: seriesName,
    },
  });

  const handleNameChange = (val: string) => {
    setValue('type_name', snakeCase(val));
  };

  const [createPartConfig, {
    loading: createLoading,
  }] = useCreatePartConfigMutation({
    ignoreResults: true,
    onCompleted: () => {
      navigate(`/${seriesPath}/part-config`);
      toaster.show({ bg: 'success' }, 'Success', 'Part Config successfully created');
      refetch();
    },
    onError: ({ message }) => {
      toaster.show({ bg: 'danger' }, 'Error', `Error creating part config: ${message}`);
    },
  });

  const onSubmit = async (data: CreatePartConfigInput) => {
    await createPartConfig({ variables: { partConfigInput: { ...data, series_id: events?.events[0].series.id}  }});
  };

  return (
    <>
      <h2 className={classNames(styles.header, "mx-auto")}>Add Part Config</h2>
      <Form
        className={classNames(styles.form, "mx-auto")}
        onSubmit={e => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Form.Label>Display Name</Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            size="sm"
            {...register('display_name', { required: true })}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <ErrorMessage
            errors={errors}
            name="display_name"
            render={() => <p className={classNames(styles.error_message)}>Display Name is required</p>}
          />
        </Form.Group>
        <Form.Label>Mileage Limit</Form.Label>
        <Form.Group className="mb-3">
          <InputGroup>
            <Form.Control
              type="number"
              size="sm"
              {...register('mileage_limit', {
                setValueAs: v => v ? toNumber(v) : undefined,
              })}
            />
            <InputGroup.Text>Miles</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Label>Mileage Warning Percentage</Form.Label>
        <Form.Group className="mb-3">
          <InputGroup>
            <Form.Control
              type="number"
              size="sm"
              {...register('mileage_warning_percentage', {
                setValueAs: v => v ? toNumber(v) : undefined,
              })}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            {...register('expires')}
            type="checkbox"
            id="expires"
            label="Expires"
          />
          <Form.Check
            {...register('serializable')}
            type="checkbox"
            id="serializable"
            label="Serializable"
          />
        </Form.Group>
        <div className={styles.actions}>
          <Button variant="secondary" onClick={() => navigate(`/${seriesPath}/part-config`)}>Cancel</Button>
          <Button type="submit">{
            createLoading ? <Spinner animation="border" size="sm" /> : 'Save'
          }</Button>
        </div>
      </Form>
    </>
  )
};
