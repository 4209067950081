import React from 'react';
import * as Sentry from '@sentry/browser';
import { Alert } from 'react-bootstrap';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Alert variant="danger">
          <p>
            An error prevented this view from rendering.
            <br />
            The error message has been logged. If you continue to see this error, please notify the dev team.
          </p>
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
